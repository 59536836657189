/** @jsx jsx */
import { jsx } from '@emotion/core';
import BlockContent from '@sanity/block-content-to-react';
import { Flex } from 'rebass';
import textSerializer from '../updatedTextSerializer';
import ReactMarkdown from 'react-markdown';
import React from 'react';
import remarkGfm from 'remark-gfm';

// Legal document
function UpdatedLegalDocument(props) {
  return (
    <Flex bg="#F9FAF1" minHeight="100dvh" pt={['37.5%', '25%', '15%']} pb="15%">
      <Flex
        flexDirection="column"
        mx={[23, 75, 125]}
        color="#1E1E1E"
        fontFamily="Poppins, sans-serif"
        fontSize={[13, 13, 19]}
      >
        {!props.markdown ? (
          <BlockContent
            blocks={props.textBlocks}
            serializers={textSerializer}
          />
        ) : (
          // @ts-ignore
          <ReactMarkdown remarkPlugins={remarkGfm}>
            {props.markdown}
          </ReactMarkdown>
        )}
      </Flex>
    </Flex>
  );
}

export default UpdatedLegalDocument;
