import { Flex, Image, Text } from 'rebass';
import SwiperSlideComponent from '../swiperSlideComponent';
import React from 'react';
import { PagePanel, PagePanelProps } from '../page-panels/pagePanel';

type AboutUsProps = {
  aboutUsPanels: Array<PagePanelProps>;
};

const UpdatedAboutUs = ({ aboutUsPanels }: AboutUsProps) => {
  return aboutUsPanels.map(panel => {
    return (
      <SwiperSlideComponent key={panel.panelType}>
        {(() => {
          switch (panel.panelType) {
            case 'ABOUT_HEADER':
              return (
                <PagePanel
                  header={panel.header}
                  buttonText={panel.buttonText}
                  image={panel.image}
                  imageMobile={panel.imageMobile}
                  bgColor={panel.bgColor}
                  txtColor={panel.txtColor}
                  panelType={panel.panelType}
                />
              );
            case 'ABOUT_BELIEVE':
              const [
                headerSentence,
                descriptionPart1,
                descriptionPart2,
              ] = panel.description.split('\n');
              return (
                <Flex
                  key={panel.panelType}
                  height="100dvh"
                  width="100dvw"
                  bg={panel.bgColor}
                  alignItems="flex-end"
                  sx={{
                    background:
                      'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
                  }}
                >
                  <Flex
                    flexDirection="column"
                    justifyContent={'center'}
                    mx={[23, 75, 125]}
                    height="calc(100% - 105px)"
                  >
                    <Flex mb={['7.5%', '10%', '3%']}>
                      <Text color={panel.txtColor} variant="h920">
                        {panel.header}
                      </Text>
                    </Flex>
                    <Flex flexDirection={['column', 'column', 'row']}>
                      <Flex
                        flexDirection="column"
                        width={['auto', 'auto', '150%']}
                        mr={['0%', '5%', '7.5%']}
                        mb={['5%', '5%', '0%']}
                      >
                        <Text
                          color={panel.txtColor}
                          variant="subH920"
                          fontWeight="extrabold"
                        >
                          {headerSentence}
                          <span
                            style={{
                              fontWeight: 400,
                            }}
                          >
                            {descriptionPart1}
                          </span>
                        </Text>
                      </Flex>
                      <Text color={panel.txtColor} variant="subH920">
                        {descriptionPart2}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              );
            case 'ABOUT_FEATURES':
              const imageObjects = panel.multImages;
              const images = imageObjects.map(imageObj => [
                imageObj.image.asset.url,
                imageObj.caption,
              ]);
              return (
                <Flex
                  key={panel.panelType}
                  height="100dvh"
                  width="100dvw"
                  bg={panel.bgColor}
                  alignItems={['flex-end', 'flex-end', 'center']}
                  sx={{
                    background:
                      'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
                  }}
                >
                  <Flex
                    flexDirection={['column', 'column', 'row']}
                    width={'100%'}
                    height={[
                      'calc(100% - 105px)',
                      'calc(100% - 105px)',
                      '100%',
                    ]}
                    mx={[23, 75, 125]}
                    alignItems={['center', 'center', 'flex-end']}
                    justifyContent={['center', 'center', 'space-between']}
                  >
                    {images.map(([url, caption], i) => {
                      const [headerCaption, subHeaderCaption] = caption.split(
                        '\n',
                      );
                      return (
                        <Flex
                          key={url}
                          flexDirection={
                            i % 2 === 0
                              ? ['row', 'row', 'column']
                              : ['row-reverse', 'row-reverse', 'column']
                          }
                          width={['100%', '100%', '30%']}
                          height={['25%', '25%', 'calc(100% - 105px)']}
                          justifyContent={[
                            'space-evenly',
                            'space-evenly',
                            'center',
                          ]}
                          alignItems={'center'}
                        >
                          <Image
                            height={['100%', '100%', '35%']}
                            src={url}
                            mb={['0%', '0%', '15%']}
                            ml={i % 2 !== 0 && ['0%', '5%', '0%']}
                            sx={{
                              objectFit: 'contain',
                            }}
                          />
                          <Flex
                            flexDirection="column"
                            width={['40%', 'auto', 'auto']}
                            textAlign="center"
                          >
                            <Text
                              color={panel.txtColor}
                              variant="subH920"
                              fontWeight="extrabold"
                            >
                              {headerCaption}
                            </Text>
                            <Text variant="subH920" color={panel.txtColor}>
                              {subHeaderCaption}
                            </Text>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Flex>
                </Flex>
              );
            case 'ABOUT_REVIEWS':
              const reviews = panel.multImages.map(obj => obj.caption);
              return (
                <PagePanel
                  header={panel.header}
                  description={panel.description}
                  buttonText={panel.buttonText}
                  image={panel.image}
                  imageMobile={panel.imageMobile}
                  bgColor={panel.bgColor}
                  txtColor={panel.txtColor}
                  panelType={panel.panelType}
                  reviews={reviews}
                />
              );
            default:
              return null;
          }
        })()}
      </SwiperSlideComponent>
    );
  });
};

export default UpdatedAboutUs;
