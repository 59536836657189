import { Box, Heading, Image, Text, Flex } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import colors from '../components/themes/colors';

export function ProductBlurb(props) {
  return (
    <Wrapper mt={100}>
      <Flex
        mt={20}
        justifyContent="center"
        flexDirection={['column-reverse', 'row', 'row']}
      >
        {/* Image */}
        <Flex style={{ justifyContent: 'center' }}>
          <Flex flexGrow={1} />
          <Image
            style={{
              objectFit: 'contain',
              width: 423 / 1.5,
              height: 642 / 1.5,
            }}
            src={props.image.asset.url}
          />
        </Flex>
        {/* Content */}
        <Flex
          flexShrink={1}
          style={{ justifyContent: 'center', alignItems: 'center' }}
          textAlign={['center', 'left', 'left']}
        >
          <Box ml={[0, 40, 40]}>
            <Text variant={'h700Medium'} width={322} mb={20}>
              {props.title}
            </Text>
            <Text variant={'h400Regular'} width={322} mb={[20, 0, 0]}>
              {props.blurb}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Wrapper>
  );
}
