import React from 'react';
import { Flex, Text, Box, Image, Heading } from 'rebass';
import Wrapper from './wrapper';
import colors from './themes/colors';
import { PrimaryButton } from './button';
import { navigate } from '@reach/router';

import PartyPopperCircular from '../assets/images/PartyPopperCircular.png';

function TeammateBox(props) {
  return (
    <Box
      bg={colors.pale200_40}
      width={[156, 246, 246]}
      minHeight={[190, 300, 300]}
      margin={10}
      style={{ borderRadius: 6 }}
    >
      <Text
        marginTop={20}
        marginLeft={20}
        marginRight={20}
        variant={'h400Bold'}
      >
        {props.name}
      </Text>
      <Text
        marginLeft={20}
        marginRight={20}
        variant={'h400Bold'}
        color={colors.pale600}
      >
        {props.role}
      </Text>
      <Image
        mt={'5px'}
        style={{
          objectFit: 'contain',
          maxWidth: '100%',
          maxHeight: '80%',
          padding: '10%',
        }}
        src={props.image.asset.url}
      />
    </Box>
  );
}

// About us page
export default function OurTeam(props) {
  return (
    <>
      <Box style={{ paddingTop: 50 }}>
        <Wrapper pb={150}>
          <Heading
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 800,
              fontSize: ['42px', '42px', '42px'],
            }}
            mb={50}
            textAlign="center"
          >
            Our Team
          </Heading>
          <Flex
            style={{
              flexFlow: 'row wrap',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {props.teammates.map(item => {
              return (
                <TeammateBox
                  name={item.name}
                  role={item.role}
                  image={item.image}
                  key={item.name}
                />
              );
            })}
          </Flex>
          <Box textAlign="center" pt={100}>
            <Image src={PartyPopperCircular} />
            <Text pt={40} pb={20} variant={'h700Regular'}>
              We&#39;squore Hiring!
            </Text>
            <PrimaryButton
              onClick={() => {
                navigate('mailto:support@getgrid.app?subject=Hiring%20Inquiry');
              }}
            >
              Join Us
            </PrimaryButton>
          </Box>
        </Wrapper>
      </Box>
    </>
  );
}
