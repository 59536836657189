import React from 'react';
import { Flex, Text, Image } from 'rebass';
import { DownloadButtons } from '../../appStoreButtons';

const LandingHeaderPanel = ({
  header,
  description,
  bgColor,
  txtColor,
  imageUrl,
  imageMediumUrl,
  imageMobileUrl,
}) => {
  return (
    <Flex
      height="100dvh"
      width="100dvw"
      bg={bgColor}
      alignItems={['center', 'flex-end', 'flex-end']}
      sx={{
        background:
          'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
    >
      <Flex
        justifyContent={['center', 'center', 'flex-end']}
        alignItems={'center'}
        flexDirection={['column', 'column', 'row-reverse']}
        width={'100%'}
        pt={['20%', '0%', '0%']}
        height={['auto', 'calc(100% - 105px)', 'calc(100% - 105px)']}
      >
        <Flex
          width={['', '', '55%']}
          height={['', '50%', 'auto']}
          mb={[0, 20, 0]}
          justifyContent={['center', 'center', 'flex-end']}
          overflowX="hidden"
          overflowY="visible"
          sx={{
            position: ['static', 'static', 'absolute'],
            right: 0,
          }}
        >
          <Image
            display={['none', 'none', 'flex']}
            src={imageUrl}
            width={'100%'}
            sx={{
              objectFit: 'contain',
            }}
          />
          <Image
            display={['flex', 'none', 'none']}
            src={imageMobileUrl}
            sx={{
              objectFit: 'cover',
            }}
          />
          <Image
            display={['none', 'flex', 'none']}
            src={imageMediumUrl}
            sx={{
              objectFit: 'cover',
            }}
          />
        </Flex>
        <Flex
          flexDirection={'column'}
          justifyContent={'center'}
          ml={[23, 75, 125]}
          mr={[23, 75, 0]}
          width={['', '', '40%']}
          sx={{
            zIndex: 30,
          }}
        >
          <Text variant="h920" color={txtColor} sx={{ whiteSpace: 'pre-line' }}>
            {header}
          </Text>
          <Text variant="subH920" mt={[20, 30, 30]} color={txtColor}>
            {description}
          </Text>
          <DownloadButtons />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LandingHeaderPanel;
