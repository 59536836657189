import { useState, useEffect } from 'react';

import { visor } from '../protos';
import clientrpc from '../api/clientrpc';

type IReferral = visor.clientrpc.IGetReferralCodeInformationResponse;

// Fetch referral by voucherCode or fail with error. Null while loading.
export function useReferral(
  voucherCode: string,
): [{ value: IReferral } | { error: Error }, boolean] {
  const [state, setState] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!voucherCode) {
      setState(null);
      setIsLoading(false);
      return;
    }
    // define an async function to appease useEffect.
    async function getReferralCodeInformation() {
      try {
        const req = new visor.clientrpc.GetReferralCodeInformationRequest();
        req.voucherCode = voucherCode;
        const res = await clientrpc.getReferralCodeInformation(req);
        setState({ value: res });
      } catch (error) {
        setState({ error: error });
      } finally {
        setIsLoading(false);
      }
    }
    getReferralCodeInformation();
  }, [voucherCode]);

  return [state, isLoading];
}
