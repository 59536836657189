import { Image, Text, Flex, Box } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';

export function ValuePropModule(props) {
  return (
    <Wrapper>
      <Flex
        mt={80}
        mb={80}
        flexDirection={['column', 'row', 'row']}
        alignItems="center"
        textAlign="center"
      >
        {props.valueProps.map(item => {
          return (
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              key={item.title}
              ml={2}
              mr={2}
            >
              <Flex height={120} justifyContent="center" alignItems="flex-end">
                <Image
                  mt={'5px'}
                  style={{
                    objectFit: 'contain',
                    maxWidth: '100%',
                    maxHeight: '80%',
                    padding: '10%',
                  }}
                  src={item.image.asset.url}
                />
              </Flex>
              <Text mt={20} variant={'h600Bold'}>
                {item.title}
              </Text>
              <Text variant={'h400Regular'}>{item.description}</Text>
            </Flex>
          );
        })}
      </Flex>
    </Wrapper>
  );
}
