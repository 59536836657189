import { Box, Heading, Image, Text, Flex } from 'rebass';
import React from 'react';
import colors from '../components/themes/colors';
import { navigate } from '@reach/router';

import { PrimaryButton } from '../components/button';

import ReactSlider from 'react-slider';

function PayboostPlaygroundAmountBar(props) {
  return (
    <Flex
      bg={colors.pale600_40}
      height={[30, 40, 40]}
      style={{ overflow: 'hidden', borderRadius: 6 }}
    >
      <Box
        bg={props.color}
        style={{ height: '100%', width: `${props.percentCompletion * 100}%` }}
      />
    </Flex>
  );
}

class PayboostPlayground extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      takeHomePay: 0.7,
      taxReturn: 0.3,
    };
  }

  sliderUpdate(newValue) {
    this.setState({
      takeHomePay: newValue,
      taxReturn: 1 - newValue,
    });
  }

  render() {
    return (
      <>
        <Box
          style={{
            backgroundColor: colors.white,
            borderRadius: 6,
            paddingTop: 40,
            paddingBottom: 40,
            paddingLeft: 30,
            paddingRight: 30,
            marginBottom: 30,
          }}
        >
          <Flex>
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="space-evenly"
            >
              <Text variant="h400Medium">Take-home pay</Text>
              <Box height="20px" />
              <Text variant="h400Medium">Tax refund</Text>
            </Flex>
            <Flex flexDirection="column" flexGrow="1" ml={20}>
              <PayboostPlaygroundAmountBar
                color={colors.teal300}
                percentCompletion={this.state.takeHomePay}
              />
              <Box height="20px" />
              <PayboostPlaygroundAmountBar
                color={colors.violet200}
                percentCompletion={this.state.taxReturn}
              />
            </Flex>
          </Flex>
        </Box>

        <ReactSlider
          className="payboost-slider"
          trackClassName="payboost-slider-track"
          thumbClassName="payboost-slider-thumb"
          onChange={val => this.sliderUpdate(val / 100)}
          defaultValue={70}
        />

        <Flex mt={10}>
          <Text
            variant={'h500Medium'}
            color={colors.white}
            display={['none', 'block', 'block']}
            opacity={'0.4'}
          >
            $0
          </Text>
          <Flex flexGrow="1" />
          <Text variant={'h500Medium'} color={colors.white} opacity={'0.4'}>
            Grid Tax refund amount
          </Text>
          <Flex flexGrow="1" />
          <Text
            variant={'h500Medium'}
            color={colors.white}
            display={['none', 'block', 'block']}
            opacity={'0.4'}
          >
            $400
          </Text>
        </Flex>
      </>
    );
  }
}

export function TryPayboost(props) {
  return (
    <div style={{ maxWidth: '100vw', overflow: 'hidden' }}>
      <Box className="roundedTop" bg={colors.footerBlack} textAlign="center">
        <Box width={[318, 600, 600]} mx="auto">
          <Heading
            sx={{
              fontFamily: 'Poppins',
              fontStyle: 'bold',
              fontWeight: 800,
              fontSize: ['42px', '42px', '58px'],
              lineHeight: ['40px', '40px', '44px'],
            }}
            color={colors.white}
            textAlign="center"
            pt={[50, 100, 100]}
            pb={50}
          >
            Give Grid Tax a try
          </Heading>
          <Text mb={40} variant="h600" textAlign="center" color={colors.white}>
            You’ll bring home more money every month
          </Text>

          <PayboostPlayground />

          <div style={{ marginTop: 50 }}>
            <PrimaryButton
              bg={colors.violet200}
              hoverColor={colors.violet300}
              activeColor={colors.violet300}
              onClick={() => {
                navigate('/eligibility/email/');
              }}
            >
              Get Grid
            </PrimaryButton>
          </div>
        </Box>
      </Box>
    </div>
  );
}
