import React from 'react';
import { Flex, Box, Heading, Image } from 'rebass';
import { ThemeProvider } from 'emotion-theming';
import theme from '../theme';
// @ts-ignore
import Thanks from '../../assets/images/ThanksIllo.png';
// @ts-ignore
import ThanksMobile from '../../assets/images/ThanksIlloMobile.png';
import { isIOS } from 'react-device-detect';

function Waitlist({}) {
  return (
    <ThemeProvider theme={theme}>
      <Box height={isIOS ? '65vh' : '75vh'}>
        <Flex height={'100%'} flexDirection={'column'}>
          <Box sx={{ position: 'relative' }}>
            <Heading
              textAlign={'center'}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, 90%)',
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: ['28px', '28px', '50px'],
                lineHeight: ['40px', '40px', '75px'],
                letterSpacing: ['0.2px', '0.2px', '-0.03em'],
              }}
            >
              Thanks! You’ll hear from us soon.
            </Heading>
          </Box>
          <Box flex={1} textAlign={'center'} sx={{ position: 'relative' }}>
            <Image
              display={['block', 'block', 'none']}
              src={ThanksMobile}
              sx={{
                position: 'absolute',
                top: '90%',
                left: '50%',
                transform: 'translate(-50%, 70%)',
              }}
            />
            <Image
              display={['none', 'none', 'block']}
              src={Thanks}
              width={'625px'}
              sx={{
                position: 'absolute',
                top: '90%',
                left: '50%',
                transform: 'translate(-50%, 70%)',
              }}
            />
          </Box>
        </Flex>
      </Box>
    </ThemeProvider>
  );
}

export default Waitlist;
