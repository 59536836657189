import React from 'react';
import { Flex, Text, Image, Button } from 'rebass';
import {
  APP_DOWNLOAD_ANDROID,
  APP_STORE_ID,
  DownloadButtons,
} from '../appStoreButtons';
import { navigate } from '@reach/router';
import { isAndroid, isIOS } from 'react-device-detect';

declare var zE: any; // zendesk js hook

const FlexPagePanel = ({
  panelType,
  header,
  description,
  bgColor,
  txtColor,
  buttonText,
  imageUrl,
  imageMediumUrl,
  imageMobileUrl,
}) => {
  let styles: Style = {};
  switch (panelType) {
    case 'LANDING_CREDIT':
      styles.maxHeight = ['55%', '60%', '85%'];
      break;
    case 'LANDING_TAX':
      styles.maxHeight = ['55%', '60%', '85%'];
      break;
    case 'LANDING_SCHEDULE':
      styles.maxHeight = ['55%', '60%', '70%'];
      break;
    case 'LANDING_FEE':
      styles.mbImage = ['7.5%', '5%', '0%'];
    case 'PRICING_HEADER':
      styles.mbImage = ['12.5%', '7.5%', '0%'];
      styles.maxHeight = ['50%', '50%', '70%'];
      break;
    case 'FAQ_HEADER':
      styles.textWidth = ['auto', 'auto', '42.5%'];
      styles.maxHeight = ['60%', '60%', '85%'];
      break;
    case 'ADVANCE_HEADER':
      styles.mbImage = ['7.5%', '7.5%', '0%'];
      break;
    case 'ADVANCE_INTEREST':
      styles.maxHeight = ['50%', '55%', '65%'];
      styles.mbImage = ['7.5%', '5%', '0%'];
      break;
    case 'ADVANCE_FAST':
      styles.maxHeight = ['50%', '55%', '70%'];
      styles.mbImage = ['10%', '7.5%', '0%'];
      break;
    case 'PAYBOOST_INFORMATION':
    case 'PAYBOOST_REFUND':
    case 'PAYBOOST_W4':
    case 'PAYBOOST_CHECKIN':
      styles.maxHeight = ['60%', '60%', '80%'];
      break;
    case 'CARD_SWIPE':
      styles.maxHeight = ['60%', '55%', '85%'];
      styles.mbImage = ['7.5%', '7.5%', '0%'];
      break;
    case 'CARD_BUILD':
      styles.maxHeight = ['60%', '57.5%', '85%'];
      styles.mbImage = ['7.5%', '7.5%', '0%'];
      break;
    case 'CARD_FEES':
      styles.maxHeight = ['none', '50%', '80%'];
      styles.mbImage = ['7.5%', '7.5%', '0%'];
      break;
    default:
      styles = {};
  }
  if (!styles.imageWidth) styles.imageWidth = ['auto', '75%', '55%'];
  if (!styles.maxHeight) styles.maxHeight = ['55%', '60%', '65%'];
  if (!styles.textWidth) styles.textWidth = ['auto', 'auto', '37.5%'];
  if (!styles.mbImage) styles.mbImage = ['5%', '5%', '0%'];

  const handleClick = (): Promise<void> => {
    switch (panelType) {
      case 'LANDING_TAX':
        return navigate('/payboost');
      case 'LANDING_SCHEDULE':
        return navigate('/advance');
      case 'FAQ_HEADER':
        zE('messenger', 'open');
        break;
      case 'PRICING_HEADER':
        if (isIOS) {
          open(APP_STORE_ID);
        } else if (isAndroid) {
          open(APP_DOWNLOAD_ANDROID);
        } else {
          open(APP_STORE_ID);
        }
        break;
    }
  };

  return (
    <Flex
      height="100dvh"
      width="100dvw"
      bg={bgColor}
      alignItems="flex-end"
      sx={{
        background:
          'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
    >
      <Flex
        justifyContent={['center', 'center', 'space-between']}
        alignItems="center"
        flexDirection={['column', 'column', 'row-reverse']}
        height="calc(100% - 105px)"
        width="100%"
        ml={[23, 75, 125]}
        mr={[23, 75, 0]}
      >
        <Flex
          width={styles.imageWidth}
          maxHeight={styles.maxHeight}
          mb={styles.mbImage}
          mx="auto"
          justifyContent={['center', 'center', 'center']}
          overflow="hidden"
        >
          <Image
            display={['none', 'none', 'flex']}
            src={imageUrl}
            sx={{
              transform: styles.transform,
              objectFit: 'contain',
            }}
          />
          <Image
            display={['flex', 'none', 'none']}
            src={imageMobileUrl}
            sx={{
              objectFit: 'contain',
            }}
          />
          <Image
            display={['none', 'flex', 'none']}
            src={imageMediumUrl || imageMobileUrl}
            sx={{
              objectFit: 'contain',
            }}
          />
        </Flex>
        <Flex
          flexDirection={'column'}
          justifyContent={'center'}
          width={styles.textWidth}
          sx={{
            zIndex: 30,
          }}
        >
          <Text variant={'h920'} color={txtColor}>
            {header}
          </Text>
          <Text
            variant={'subH920'}
            mt={[20, 30, 30]}
            color={txtColor}
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            {description}
          </Text>
          {buttonText && (
            <Flex mt={[15, 30, 45]}>
              <Button
                variant="neon"
                onClick={handleClick}
                sx={{
                  border: `1.5px solid ${bgColor}`,
                  ':hover': {
                    border: '1.5px solid white',
                  },
                }}
              >
                {buttonText}
              </Button>
            </Flex>
          )}
          {styles.downloadButtons && <DownloadButtons />}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FlexPagePanel;

export type Style = {
  maxHeight?: string[] | string;
  imageWidth?: string[] | string;
  mbImage?: number[] | number | string[] | string;
  textWidth?: string[] | string;
  transform?: string;
  downloadButtons?: boolean;
};
