import React from 'react';
import { Flex, Text } from 'rebass';
import { DownloadButtons } from '../appStoreButtons';
import AboutReviewsSection from './aboutReviewsSection';
import StarLogo from '../../assets/svgs/reviewStar.svg';

const AboutReviewsPanel = ({
  bgColor,
  header,
  description,
  txtColor,
  reviews,
  imageUrl,
  imageMobileUrl,
}) => {
  return (
    <Flex
      height="100dvh"
      width="100dvw"
      bg={bgColor}
      alignItems="flex-end"
      sx={{
        background:
          'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
    >
      <Flex
        justifyContent={['center', 'center', 'space-between']}
        alignItems="center"
        flexDirection={['column', 'column', 'row-reverse']}
        width={'100%'}
        height="calc(100% - 105px)"
      >
        <Flex
          width={['100%', '80%', '55%']}
          height="100%"
          mb={[20, 20, 0]}
          ml={[0, 0, 20]}
          mr={[0, 0, 75]}
          overflow={['hidden', 'hidden', 'visible']}
        >
          <AboutReviewsSection
            txtColor={txtColor}
            reviews={reviews}
            imageUrl={imageUrl}
            imageMobileUrl={imageMobileUrl}
          />
        </Flex>
        <Flex
          height="auto"
          flexDirection={'column'}
          justifyContent={'center'}
          ml={[23, 75, 125]}
          mr={[23, 75, 0]}
          mb={['5%', '5%', '0%']}
          width={['', '', '40%']}
          sx={{
            zIndex: 30,
          }}
        >
          <Text variant={'h920'} color={txtColor}>
            {header}
          </Text>
          <Text variant={'subH920'} mt={[15, 30, 30]} color={txtColor}>
            {description}
          </Text>
          <DownloadButtons />
          <Flex
            color={txtColor}
            textAlign="center"
            justifyContent={['space-around', 'space-around', 'space-around']}
            mt={10}
            width={['auto', 'auto', '395px']}
            maxWidth={['none', 'none', '100%']}
          >
            <Flex flexDirection="column" alignItems="center">
              <Flex>
                <Text fontFamily="body" fontSize={[13, 19, 19]} mr="5px">
                  4.7
                </Text>
                <Flex alignItems="center">
                  <StarLogo style={{ height: '70%' }} />
                </Flex>
              </Flex>
              <Text fontFamily="body" fontSize={[9, 13, 13]}>
                Apple App Store
              </Text>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <Flex>
                <Text fontFamily="body" fontSize={[13, 19, 19]} mr="5px">
                  4.7
                </Text>
                <Flex alignItems="center">
                  <StarLogo style={{ height: '70%' }} />
                </Flex>
              </Flex>
              <Text fontFamily="body" fontSize={[9, 13, 13]}>
                Google Play Store
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AboutReviewsPanel;
