import React from 'react';
import { Flex, Image, Text } from 'rebass';

const CardHeaderPanel = ({
  header,
  description,
  txtColor,
  bgColor,
  imageUrl,
  imageMobileUrl,
}) => {
  return (
    <Flex
      height="100dvh"
      width="100dvw"
      bg={bgColor}
      sx={{
        background:
          'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
    >
      <Flex
        justifyContent={['center', 'center', 'flex-end']}
        alignItems={['center', 'center', 'flex-end']}
        flexDirection={['column', 'column', 'row-reverse']}
        width="100%"
      >
        <Flex
          width={['100%', '75%', '60%']}
          height={['50%', '', '100%']}
          mt={['15%', '0%', '0%']}
          mb={['5%', '5%', '0%']}
          mr={['0%', '0%', '0%']}
          justifyContent={['flex-end', 'center', 'flex-start']}
          overflow="hidden"
          sx={{
            position: ['static', 'relative', 'relative'],
          }}
        >
          <Image
            display={['none', 'none', 'flex']}
            src={imageUrl}
            sx={{
              position: 'absolute',
              objectFit: 'cover',
              top: '20%',
              transform: 'scale(1.1)',
              left: '12.5%',
            }}
          />
          <Image
            height="100%"
            width="100%"
            display={['flex', 'none', 'none']}
            src={imageMobileUrl}
            sx={{
              objectFit: 'cover',
              objectPosition: 'left center',
            }}
          />
          <Image
            display={['none', 'flex', 'none']}
            src={imageMobileUrl}
            sx={{
              objectFit: 'contain',
            }}
          />
        </Flex>
        <Flex
          flexDirection={'column'}
          justifyContent={'center'}
          ml={[23, 75, 125]}
          mr={[23, 75, 0]}
          width={['', '', '35%']}
          height={['auto', 'auto', 'calc(100% - 105px)']}
          sx={{
            zIndex: 30,
          }}
        >
          <Text variant="h920" color={txtColor}>
            {header}
          </Text>

          <Text
            variant="subH920"
            mt={[20, 30, 30]}
            color={txtColor}
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            {description}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CardHeaderPanel;
