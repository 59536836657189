import { Box, Flex, Heading, Text } from 'rebass';
import { Label, Select } from '@rebass/forms';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useLocation } from '@reach/router';
import BlockContent from '@sanity/block-content-to-react';
import React, { useState } from 'react';

import { FormButton, RoundedAccentButton } from './button';
import { emailValidator } from './validations';
import { serverErrorToast } from './toaster';
import { visor } from '../protos';
// @ts-ignore
import FAQClose from '../assets/svgs/FAQClose.svg';
// @ts-ignore
import FAQOpen from '../assets/svgs/FAQOpen.svg';
import Form from './form';
import FormInput from './inputs/formInput';
import Wrapper from './wrapper';
import clientrpc from '../api/clientrpc';
import colors from './themes/colors';
import textSerializer from './textSerializer';
import theme from './theme';
import { PrimaryButton } from '../components/button';

declare var zE: any; // zendesk js hook

export function FrequentlyAskedQuestions({ title, sections }) {
  const location = useLocation();
  // The currently visible section
  const [sectionIdx, setSectionIdx] = useState<number>(0);
  // The currently expanded element
  const [currentExpandedIdx, setCurrentExpandedIdx] = useState<number>(null);

  const currentSection = sections[sectionIdx];

  return (
    <>
      <Wrapper mb={1} my={40}>
        <Heading
          as="h2"
          variant="h700Medium"
          mb={sections.length > 1 ? 2 : [40, 40, 3]}
          style={{ textAlign: 'center' }}
        >
          {title}
        </Heading>
        {sections.length > 1 ? (
          <>
            <Box display={['block', 'none', 'none']}>
              <Label htmlFor="category" mt={3} mb={0}>
                Category
              </Label>
              <Select
                id="category"
                name="category"
                mt={1}
                mb={3}
                onChange={evt => {
                  setSectionIdx(evt.target.selectedIndex);
                  setCurrentExpandedIdx(null);
                }}
              >
                {sections.map(({ title }, idx) => {
                  return <option key={title}>{title}</option>;
                })}
              </Select>
            </Box>
            <Flex
              justifyContent="center"
              mb={[40, 40, 3]}
              display={[
                'none !important',
                'block !important',
                'block !important',
              ]}
            >
              {sections.map(({ title }, idx) => {
                return (
                  <RoundedAccentButton
                    key={title}
                    margin={'3'}
                    bg={sectionIdx === idx ? colors.black : colors.white}
                    color={sectionIdx === idx ? colors.white : colors.text}
                    hoverColor={colors.pale200}
                    sx={{
                      border: `2px solid ${colors.black}`,
                      borderRadius: 72,
                      whiteSpace: 'nowrap',
                      fontWeight: 500,
                    }}
                    onClick={() => {
                      setCurrentExpandedIdx(null);
                      setSectionIdx(idx);
                    }}
                  >
                    {title}
                  </RoundedAccentButton>
                );
              })}
            </Flex>
          </>
        ) : null}
        {currentSection.items.map((item, idx) => {
          return (
            <Item
              visible={idx === currentExpandedIdx}
              highlight={false}
              setVisible={newVisible => {
                // Set visible to idx.
                setCurrentExpandedIdx(newVisible ? idx : null);
              }}
              key={item._key}
              showBottomBorder={idx === currentSection?.items.length - 1}
              title={item.title}
              description={item.description}
            />
          );
        })}
      </Wrapper>
      <Wrapper bg={'backgroundPale'}>
        <ContactUsForm />
      </Wrapper>
    </>
  );
}

function Item({
  title,
  description,
  visible,
  highlight,
  setVisible,
  showBottomBorder,
}) {
  return (
    <Flex
      flexDirection="column"
      overflow="hidden"
      sx={{
        // height: visible ? expandedHeight : 110,
        cursor: visible ? 'auto' : 'pointer',
        transition: '0.2s height ease-in-out, 1s background ease-in-out',
        backgroundColor: highlight ? theme.colors.teal100 : 'inherit',
        ':hover': {
          backgroundColor: theme.colors.white,
        },
        borderTop: '1px solid',
        borderTopColor: theme.colors.pale200,
      }}
      css={
        showBottomBorder
          ? {
              borderBottom: '1px solid',
              borderBottomColor: theme.colors.pale200,
            }
          : null
      }
      onClick={() => {
        if (!visible && setVisible) {
          setVisible(!visible);
        }
      }}
    >
      <Flex justifyContent="space-between" alignItems="center" width="100%">
        <Heading
          as="h3"
          variant={['h600Medium', 'h500Medium', 'h500Medium']}
          mt={[4, 4, 40]}
          mb={[4, 4, 42]}
          mr={3}
        >
          {title}
        </Heading>
        <a
          href="#"
          onClick={evt => {
            evt.preventDefault();
            setVisible(!visible);
          }}
        >
          {visible ? <FAQClose /> : <FAQOpen />}
        </a>
      </Flex>
      <Box
        maxHeight={visible ? 'auto' : 0}
        sx={{
          transition: '0.4s opacity ease-in-out',
        }}
        opacity={visible ? 1 : 0}
        mt={visible ? -4 : 0}
      >
        <BlockContent blocks={description} serializers={textSerializer} />
      </Box>
    </Flex>
  );
}

function ContactUsForm({}) {
  const form = useForm();
  const { handleSubmit, reset } = form;
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async data => {
    const req = new visor.clientrpc.CreateContactUsLeadRequest();
    req.email = data.email;
    req.name = data.name;
    req.message = data.message;

    setSubmitting(true);
    try {
      await clientrpc.createContactUsLead(req);
      reset();
      toast.success(
        'Contact form submitted! We will reach out to you shortly.',
      );
    } catch (e) {
      serverErrorToast();
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <Flex flexDirection="column" alignItems="center">
      <Heading
        sx={{
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: ['28px', '28px', '32px'],
          lineHeight: ['40px', '40px', '44px'],
        }}
        mb={'32px'}
        mt={'32px'}
      >
        Still have questions?
      </Heading>
      <PrimaryButton
        onClick={() => {
          zE('messenger', 'open');
        }}
      >
        Chat with us
      </PrimaryButton>
    </Flex>
  );
}
