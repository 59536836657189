import React from 'react';
import { OverlayRoute } from '../overlay';
import Waitlist from '../eligibility/waitlist';

const EligibilityWaitlist = () => {
  return (
    <OverlayRoute path={'eligibility/waitlist'}>
      <Waitlist />
    </OverlayRoute>
  );
};

export default EligibilityWaitlist;
