import React from 'react';
import { Button, Flex, Image, Text } from 'rebass';
import { navigate } from '@reach/router';

const TosHeaderPanel = ({
  header,
  bgColor,
  txtColor,
  imageUrl,
  imageMobileUrl,
  buttonText,
}) => {
  const buttonTexts = buttonText.split('\n');
  const buttons = buttonTexts.map((text, i) => {
    return {
      id: i,
      text,
      isLong: text.length > 20,
    };
  });
  const handleClick = termPage => {
    switch (termPage) {
      case 'Terms of Service':
        return navigate('/terms/tos');
      case 'Rewards Terms':
        return navigate('/terms/rewards');
      case 'Income Protection Guarantee':
        return navigate('/terms/income-protection');
      default:
        return;
    }
  };
  return (
    <Flex
      height="100dvh"
      width="100dvw"
      bg={bgColor}
      alignItems="flex-end"
      sx={{
        background:
          'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
    >
      <Flex
        justifyContent={['center', 'center', 'space-between']}
        alignItems="center"
        flexDirection={['column', 'column', 'row-reverse']}
        height="calc(100% - 105px)"
        width="100%"
        ml={[23, 75, 125]}
        mr={[23, 75, 0]}
      >
        <Flex
          width={['auto', '75%', '55%']}
          maxHeight={['60%', '60%', '75%']}
          mb={['17.5%', '5%', '0%']}
          mx="auto"
          justifyContent={['center', 'center', 'center']}
          overflow="hidden"
        >
          <Image
            display={['none', 'none', 'flex']}
            src={imageUrl}
            sx={{
              objectFit: 'contain',
            }}
          />
          <Image
            display={['flex', 'flex', 'none']}
            src={imageMobileUrl}
            sx={{
              objectFit: 'contain',
            }}
          />
        </Flex>
        <Flex
          flexDirection={'column'}
          justifyContent={'center'}
          width={['auto', 'auto', '37.5%']}
          sx={{
            zIndex: 30,
          }}
        >
          <Text variant={'h920'} color={txtColor}>
            {header}
          </Text>
          <div
            style={{
              marginTop: '7.5%',
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridAutoRows: 'min-content',
              gap: '2.5%',
              width: '85%',
            }}
          >
            {buttons.map(button => {
              return (
                <Button
                  key={button.id}
                  variant="neon"
                  onClick={() => handleClick(button.text)}
                  mb="7.5%"
                  sx={{
                    wordBreak: 'break-word',
                    gridColumn: button.isLong && '1 / span 2',
                    border: `1.5px solid ${bgColor}`,
                    ':hover': {
                      border: '1.5px solid white',
                    },
                  }}
                >
                  {button.text}
                </Button>
              );
            })}
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TosHeaderPanel;
