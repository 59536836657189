import { Box, Flex, Heading, Image, Text } from 'rebass';
import { navigate } from 'gatsby';
import { render } from 'mustache';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';

import { AppleAppStoreButton, GooglePlayButton } from './appStoreButtons';
import { FormButton } from './button';
import { emailValidator } from './validations';
import { serverErrorToast } from './toaster';
import { useReferral } from '../data/useReferral';
import { visor } from '../protos';
import FormInput from './inputs/formInput';
// @ts-ignore
import Gift from '../assets/svgs/Gift.svg';
// @ts-ignore
import Lady from '../assets/images/Lady3Illo.png';
import Wrapper, { wrapperWidth } from './wrapper';
import clientrpc, { TwirpError } from '../api/clientrpc';
import * as content from '../copy/Strings.gen';

type IReferral = visor.clientrpc.IGetReferralCodeInformationResponse;

async function submitReferralLead(email: string): Promise<any> {
  if (!email || email.length === 0) {
    throw new Error();
  }
}

export function ReferralWebPickup({
  data,
}: {
  data: {
    code: string;
    referral: { value: IReferral } | { error: Error };
  };
}) {
  const code = data?.code;
  const referral = data?.referral;

  const [state, setState] = useState('FORM');

  // Ensure there's a referral code in the URL.
  if (!code || code.length === 0) {
    navigate('/');
    setTimeout(() => {
      toast.warning(
        'This referral link isn’t working right now. Please make sure it’s just right.',
      );
    }, 1000);
    return null;
  }

  switch (state) {
    case 'FORM':
      const referralError = referral as { error: Error };
      if (referralError?.error) {
        return <ReferralWebPickupError error={referralError?.error} />;
      }

      return (
        <ReferralWebPickupForm
          code={code}
          referral={(referral as { value: IReferral })?.value}
          onSuccess={() => {
            setState('SUCCESS');
          }}
        />
      );
    case 'SUCCESS':
      return <ReferralWebPickupSuccess />;
  }
}

function ReferralWebPickupError({ error }: { error: Error }) {
  return (
    <Wrapper my={[5, 5, 6]}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Box>
          <Heading
            variant="h700Medium"
            mb={[3, 3, 1]}
            sx={{
              whiteSpace: [null, null, 'pre-line'],
            }}
          >
            {sanitizedError(error)}
          </Heading>
          <Text
            variant="h400Regular"
            sx={{
              lineHeight: [null, null, '28px'],
              fontSize: [null, null, '20px'],
            }}
          >
            You can still download the app!
          </Text>
          <Box mt={[4, 4, 3]}>
            <AppleAppStoreButton context="OVERLAY" />
          </Box>
        </Box>
      </Flex>
    </Wrapper>
  );
}

function sanitizedError(error: Error): string {
  const defaultMessage = `Something went wrong.`;
  if (!error) {
    return defaultMessage;
  }
  if (error instanceof TwirpError) {
    return (
      content.string({ key: error?.meta?.localized_message }) || defaultMessage
    );
  } else {
    return defaultMessage;
  }
}

function ReferralWebPickupForm({
  code,
  referral,
  onSuccess,
}: {
  code: string;
  referral: IReferral;
  onSuccess: () => void;
}) {
  const form = useForm();
  const { handleSubmit } = form;
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async data => {
    setSubmitting(true);
    try {
      const req = new visor.clientrpc.CreateReferralLeadRequest();
      req.refereeEmail = data.email;
      req.voucherCode = code;
      const res = await clientrpc.createReferralLead(req);
      console.info(`createReferralLead ok: ${res}`);
      toast.info('Please download the app to continue.');
      onSuccess();
    } catch (ex) {
      console.error(`Referral pickup failed: ${ex}`);
      serverErrorToast();
    } finally {
      setSubmitting(false);
    }
  };

  // Loading state. For now, it's just blank, since the response time is fast
  // and spinners make it feel more jerky.
  if (!referral) {
    return null;
  }

  return (
    <Wrapper my={[4, 4, 6]}>
      <Flex mt={[0, 0, 6]} mb={[4, 4, 6]}>
        <Flex
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          flexDirection="column"
          justifyContent={['center']}
        >
          <Heading
            variant={'h700Regular'}
            mb={3}
            sx={{
              fontWeight: [null, null, 500],
              lineHeight: [null, null, '75px'],
              fontSize: [null, null, '50px'],
              letterSpacing: [null, null, '-0.03em'],
              whiteSpace: [null, null, 'pre-line'],
            }}
          >
            {render(content.string(referral?.referralTitle), {
              firstName: referral?.referrerFirstName || `Your friend`,
            })}
          </Heading>
          <Text
            variant="h400Regular"
            mb={3}
            sx={{
              lineHeight: [null, null, '28px'],
              fontSize: [null, null, '20px'],
              whiteSpace: [null, null, 'pre-line'],
            }}
          >
            {content.string(referral?.referralDetails)}
          </Text>
          <FormInput
            type="email"
            form={form}
            validation={emailValidator}
            name="email"
            placeholder="Email"
            label="Email"
            disabled={submitting}
          />
          <Box alignSelf="center">
            <FormButton loading={submitting} type={'submit'}>
              Download and Claim
            </FormButton>
          </Box>
        </Flex>
        <Image
          display={['none', 'none', 'block']}
          flex="0 0 339px"
          src={Lady}
          width={'339px'}
          height={'233px'}
        />
      </Flex>
    </Wrapper>
  );
}

function ReferralWebPickupSuccess() {
  return (
    <Wrapper my={[4, 4, 6]}>
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Box>
          <Heading
            variant="h700Regular"
            mt={3}
            mb={[2, 2, 2]}
            sx={{
              whiteSpace: 'pre-line',
              fontWeight: [null, null, 500],
            }}
          >
            {content.referralProgramWebPickupSuccess}
          </Heading>
          <Text
            variant="h400Regular"
            mt={3}
            mb={3}
            sx={{
              fontSize: [null, null, '20px'],
              lineHeight: [null, null, '28px'],
            }}
          >
            {content.referralProgramWebPickupSuccessSubtitle}
          </Text>
          <Flex display="flex" flexDirection="row" justifyContent="start">
            <Box pr={1}>
              <AppleAppStoreButton context="OVERLAY" />
            </Box>
            <Box px={1}>
              <GooglePlayButton context="HERO" />
            </Box>
          </Flex>
          <Box
            sx={{
              position: ['fixed', 'fixed', 'static'],
              width: ['100%', '100%', '335px'],
              bottom: ['100px', '100px', 'unset'],
              left: [0, 0, 'unset'],
            }}
            px={[20, 20, 0]}
          >
            <Flex
              bg="#F6F7F8"
              mt={[0, 0, 4]}
              mb={[0, 0, 3]}
              width={[wrapperWidth, wrapperWidth, 'auto']}
              mx={['auto', 'auto', 0]}
              px={10}
              py={8}
              sx={{
                borderRadius: '4px',
              }}
            >
              <Box flex="0 0 20px" mr={12}>
                <Gift />
              </Box>
              <Text
                variant="h300Regular"
                fontWeight={500}
                fontSize={'13px'}
                lineHeight={'21px'}
              >
                {content.referralProgramWebPickupSuccessHelp}
              </Text>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </Wrapper>
  );
}
