import React from 'react';
import { OverlayRoute } from '../overlay';
import EligibilityForm from '../eligibility/eligibilityForm';

export const eligibilityEmailPath = 'eligibility/email';

const EligibilityOverlay = () => {
  return (
    <OverlayRoute path={'eligibility/questionnaire'}>
      <EligibilityForm />
    </OverlayRoute>
  );
};

export default EligibilityOverlay;
