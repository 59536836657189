import { Box, Heading, Image, Text, Flex } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import colors from '../components/themes/colors';

import BottomCircularRect from '../assets/svgs/bottomCircularRect.svg';
import { BulletCheck } from '../assets/svgs/BulletCheck';
import { PrimaryButton } from '../components/button';
import { navigate } from '@reach/router';

function PricingRowLg(props) {
  return (
    <>
      {/* Row */}
      <Flex flexDirection="row">
        {/* Description */}
        <Box width="76%">
          <Flex height="100px" flexDirection="column" justifyContent="flex-end">
            <Text ml={30} variant={'h400Medium'}>
              {props.title}
            </Text>
            <Text ml={30} variant={'h300Regular'} width="60%">
              {props.description}
            </Text>
          </Flex>
        </Box>

        {/* Free Check */}
        <Box width="25%">
          {props.isFree === true && (
            <Flex
              height="100px"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <BulletCheck
                stroke={colors.white}
                fill={colors.teal300}
                ml={80}
              />
            </Flex>
          )}
        </Box>

        {/* Grid+  Check */}
        {props.isPlus === true && (
          <Box width="18%">
            <Flex
              height="100px"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <BulletCheck
                stroke={colors.white}
                fill={colors.teal300}
                ml={80}
              />
            </Flex>
          </Box>
        )}
      </Flex>

      {/* Separator */}
      <Box
        ml={30}
        mr={30}
        mt={20}
        height={1}
        bg={colors.pale200}
        display={props.withSeparator === true ? 'block' : 'none'}
      />
    </>
  );
}

function PricingLg() {
  return (
    <Box
      bg={colors.white}
      style={{ borderRadius: 6, boxShadow: '4px 11px 24px #00000017' }}
      pb={30}
    >
      {/* Top row */}
      <Flex flexDirection="row" pt={20}>
        {/* Features */}
        <Box width="60%">
          <Flex height="100px" flexDirection="column" justifyContent="flex-end">
            <Text ml={30} variant={'h600Bold'}>
              Features
            </Text>
          </Flex>
        </Box>

        {/* Free $0 */}
        <Box width="20%">
          <Flex height="100px" flexDirection="column" justifyContent="flex-end">
            <Text ml={30} variant={'h600Bold'}>
              Free
            </Text>
            <Text ml={30} variant={'h820'}>
              $0
            </Text>
          </Flex>
        </Box>

        {/* Grid+ $10/month */}
        <Box width="20%">
          <Flex height="100px" flexDirection="column" justifyContent="flex-end">
            <Text ml={30} variant={'h600Bold'}>
              Grid+
            </Text>
            <Flex justifyContent="flex-start" alignItems="flex-end">
              <Text ml={30} variant={'h820'}>
                $10
              </Text>
              <Text mb={2} variant={'h300Regular'} color={colors.black}>
                /month
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>

      {/* Payboost */}
      <PricingRowLg
        title="Grid Tax"
        description="Get up to $250 more each paystub by paying less taxes."
        isFree={true}
        isPlus={true}
        withSeparator={true}
      />

      {/* Cashboost */}
      <PricingRowLg
        title="Grid Advance"
        description="Up to $200 advances if you need extra cash quickly."
        isFree={false}
        isPlus={true}
        withSeparator={true}
      />

      {/* Repayment Snooze */}
      <PricingRowLg
        title="Repayment snooze (coming soon)"
        description="Get more time to pay back your advance. No consequences to you."
        isFree={false}
        isPlus={true}
        withSeparator={true}
      />

      {/* Free tax projection */}
      <PricingRowLg
        title="Free tax projection"
        description="No more black-box, find out how much refund you'll get next year."
        isFree={false}
        isPlus={true}
        withSeparator={true}
      />

      {/* BoostCard */}
      <PricingRowLg
        title="Grid Card"
        description="The only card you'll need in your wallet going foward."
        isFree={false}
        isPlus={true}
        withSeparator={false}
      />
    </Box>
  );
}

function PricingRowSm(props) {
  return (
    <>
      {/* Free tax projection */}
      <Flex flexDirection="row" paddingRight="30px">
        {/* Description */}
        <Flex flexGrow="7" marginTop="30px">
          <Flex
            height="100px"
            flexDirection="column"
            justifyContent="center"
            width="90%"
          >
            <Text ml={30} variant={'h400Medium'}>
              {props.title}
            </Text>
            <Text ml={30} variant={'h300Regular'}>
              {props.description}
            </Text>
          </Flex>
        </Flex>

        {/* Free Check */}
        <Flex flexGrow="1">
          <Flex
            height="100px"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            marginTop="30px"
          >
            {props.isChecked === true && (
              <BulletCheck
                stroke={colors.white}
                fill={colors.teal300}
                ml={80}
              />
            )}
          </Flex>
        </Flex>
      </Flex>

      {/* Separator */}
      <Box
        display={props.withSeparator === true ? 'block' : 'none'}
        ml={30}
        mr={30}
        mt={20}
        height={1}
        bg={colors.pale200}
      />
    </>
  );
}

class PricingSm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentlySelected: 'free',
    };
  }

  PricingSmFree() {
    return (
      <Box
        bg={colors.white}
        style={{ borderRadius: 6, boxShadow: '4px 11px 24px #00000017' }}
        overflow="hidden"
        marginTop="30px"
        paddingBottom={15}
      >
        <PricingRowSm
          title="Grid Tax"
          description="Get up to $250 more each paystub by paying less taxes."
          isChecked={true}
          withSeparator={true}
        />
        <PricingRowSm
          title="Grid Advance"
          description="Up to $200 advances if you need extra cash quickly."
          isChecked={false}
          withSeparator={true}
        />
        <PricingRowSm
          title="Repayment snooze (coming soon)"
          description="Get more time to pay back your advance."
          isChecked={false}
          withSeparator={true}
        />
        <PricingRowSm
          title="Free tax projection"
          description="No more black-box, find out how much refund you'll get next year."
          isChecked={false}
          withSeparator={true}
        />
        <PricingRowSm
          title="Grid Card"
          description="The only card you'll need in your wallet going foward."
          isChecked={false}
          withSeparator={false}
        />
      </Box>
    );
  }

  PricingSmGridPlus() {
    return (
      <Box
        bg={colors.white}
        style={{ borderRadius: 6, boxShadow: '4px 11px 24px #00000017' }}
        overflow="hidden"
        marginTop="30px"
        paddingBottom={15}
      >
        <PricingRowSm
          title="Grid Tax"
          description="Get up to $250 more each paystub by paying less taxes."
          isChecked={true}
          withSeparator={true}
        />
        <PricingRowSm
          title="Grid Advance"
          description="Up to $200 advances if you need extra cash quickly."
          isChecked={true}
          withSeparator={true}
        />
        <PricingRowSm
          title="Repayment snooze (coming soon)"
          description="Get more time to pay back your advance."
          isChecked={true}
          withSeparator={true}
        />
        <PricingRowSm
          title="Free tax projection"
          description="No more black-box, find out how much refund you'll get next year."
          isChecked={true}
          withSeparator={true}
        />
        <PricingRowSm
          title="Grid Card"
          description="The only card you'll need in your wallet going foward."
          isChecked={true}
          withSeparator={false}
        />
      </Box>
    );
  }

  render() {
    return (
      <>
        {/* Control row */}
        <Box
          bg={colors.white}
          style={{ borderRadius: 6, boxShadow: '4px 11px 24px #00000017' }}
          overflow="hidden"
        >
          {/* Top row */}
          <Flex flexDirection="row" justifyContent="center">
            {/* Free $0 */}
            <Box
              backgroundColor={
                this.state.currentlySelected === 'free'
                  ? colors.teal100
                  : colors.white
              }
              flexGrow="1"
              width="50%"
              paddingLeft="20px"
              onClick={() => {
                this.setState({ currentlySelected: 'free' });
              }}
            >
              <Flex
                height="100px"
                flexGrow="1"
                flexDirection="column"
                justifyContent="center"
              >
                <Text ml={30} variant={'h600Bold'}>
                  Free
                </Text>
                <Text ml={30} variant={'h820'}>
                  $0
                </Text>
              </Flex>
            </Box>

            {/* Grid+ $10/month */}
            <Box
              flexGrow="1"
              width="50%"
              backgroundColor={
                this.state.currentlySelected === 'gridPlus'
                  ? colors.teal100
                  : colors.white
              }
              onClick={() => {
                this.setState({ currentlySelected: 'gridPlus' });
              }}
            >
              <Flex
                height="100px"
                flexGrow="1"
                flexDirection="column"
                justifyContent="center"
              >
                <Text ml={30} variant={'h600Bold'}>
                  Grid+
                </Text>
                <Flex justifyContent="flex-start" alignItems="flex-end">
                  <Text ml={30} variant={'h820'}>
                    $10
                  </Text>
                  <Text mb={2} variant={'h300Regular'} color={colors.black}>
                    /month
                  </Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Box>

        {this.state.currentlySelected === 'free' && <this.PricingSmFree />}
        {this.state.currentlySelected === 'gridPlus' && (
          <this.PricingSmGridPlus />
        )}
      </>
    );
  }
}

export function Pricing(props) {
  return (
    <>
      <Wrapper bg={colors.footerBlack}>
        <Heading
          sx={{
            fontFamily: 'Poppins',
            fontStyle: 'bold',
            fontWeight: 900,
            fontSize: ['62px', '62px', '80px'],
            lineHeight: ['70px', '70px', '44px'],
          }}
          color={colors.white}
          textAlign="center"
          pt={[50, 100, 100]}
          pb={50}
        >
          Our plans
        </Heading>

        <Text
          variant={'h500Regular'}
          color={colors.white}
          textAlign="center"
          pb={100}
        >
          Transparent and simple pricing with no hidden fees.
        </Text>
      </Wrapper>
      <BottomCircularRect fill={colors.footerBlack} style={{ zIndex: 0 }} />
      {/* Pricing block Large */}
      <Wrapper
        display={['none', 'block', 'block']}
        mt={[0, -130, -200]}
        mb={100}
        style={{ zIndex: 150, position: 'relative' }}
      >
        <PricingLg />
      </Wrapper>

      {/* Pricing block Small */}
      <Wrapper
        display={['block', 'none', 'none']}
        mt={[-100, 0, 0]}
        mb={100}
        style={{ zIndex: 150, position: 'relative' }}
      >
        <PricingSm />
      </Wrapper>

      <Wrapper mb={50} textAlign="center">
        <Text variant={'h700Medium'} textAlign="center" mb={15}>
          Our Commitment
        </Text>
        <Text variant={'h400Regular'} textAlign="center" mb={30}>
          We will always be 100% transparent about our pricing. No hidden fees.
          No selling your data. Never.{' '}
        </Text>
        <PrimaryButton
          onClick={() => {
            navigate('/eligibility/email/');
          }}
        >
          Get Started for Free!
        </PrimaryButton>
      </Wrapper>
    </>
  );
}
