import { Box, Heading, Image, Text, Flex } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import Divider from './divider';

export function Tutorial(props) {
  return (
    <Wrapper>
      <Box pt={96} pb={10}>
        <Wrapper>
          <Flex mb={[0, 0, -120]}>
            <Box flex={1}>
              <Heading display={['none', 'none', 'block']} variant="text.h900">
                {props.title}
              </Heading>
            </Box>
            <Box flex={1} />
          </Flex>
          {props.steps.map((step, index) => (
            <Step index={index} {...step} key={'tutorial-step-' + index} />
          ))}
        </Wrapper>
      </Box>
    </Wrapper>
  );
}

function Step(props) {
  const evenIndex = props.index % 2 === 0;
  return (
    <>
      <Flex
        mt={[80, 80, 90]}
        flexDirection={['column', 'column', evenIndex ? 'row' : 'row-reverse']}
      >
        <Box
          pl={[0, 0, evenIndex ? 0 : 24]}
          pr={[0, 0, evenIndex ? 24 : 0]}
          my="auto"
          flex={1}
        >
          <Flex flexDirection="column">
            <Heading mb={22} variant="text.h700Medium">
              {props.title}
            </Heading>
            <Box
              mb={28}
              width={100}
              height={5}
              sx={{
                borderRadius: 5,
              }}
              bg={props.accentColor}
            />
            <Text variant="h500Regular">{props.subtitle}</Text>
          </Flex>
        </Box>
        <Box
          pl={[0, 0, evenIndex ? 24 : 0]}
          pr={[0, 0, evenIndex ? 0 : 24]}
          flex={1}
          width="100%"
        >
          <Image src={props.screenshot.asset.url} />
        </Box>
      </Flex>
      <Divider mt="-6px" />
    </>
  );
}
