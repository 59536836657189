import React, { useMemo, useState } from 'react';
import { Box, Image, Text } from 'rebass';
import useResizeObserver from 'use-resize-observer';
import { useWindowHeight } from '@react-hook/window-size';
import Sticky from 'react-stickynode';
import Slider from 'rc-slider';
import TextTransition, { presets } from 'react-text-transition';
import 'rc-slider/assets/index.css';
import { PrimaryButton } from './button';
import MagnifyingGlass from '../assets/svgs/boostcardpromo/magnifying-glass.svg';
import NoMinimum from '../assets/svgs/boostcardpromo/no-minimum.svg';
import NoHiddenFees from '../assets/svgs/boostcardpromo/no-hidden-fees.svg';
import PaymentMethod from '../assets/svgs/boostcardpromo/payment-method.svg';
import Friends from '../assets/svgs/boostcardpromo/friends.svg';
import Bars from '../assets/svgs/boostcardpromo/bars.svg';
import useReducedMotion from '../hooks/use-reduced-motion';
import usePrevious from '../hooks/use-previous';
import estimateCardRewards from '../utils/estimate-card-rewards';
import { ClassNames } from '@emotion/core';

const FeatureWithIconAndText = ({ text, icon, ...rest }) => (
  <Box textAlign="center" {...rest}>
    {icon}

    <Text variant="h300Regular" mt="5px">
      {text}
    </Text>
  </Box>
);

const FeatureDivider = () => (
  <Box
    width="1px"
    height={150}
    bg="#B5BBC673"
    mx={65}
    display={['none', 'block']}
  />
);

const Stat = ({ num, text }) => (
  <Box
    textAlign="center"
    display="flex"
    flexDirection="column"
    alignItems="center"
    mb={60}
  >
    <Text variant="h910">{num}</Text>

    <Text variant="h300Regular" maxWidth={[180, 250]}>
      {text}
    </Text>
  </Box>
);

const StickyCard = ({ url, bottomBoundarySelector }) => {
  const shouldReduceMotion = useReducedMotion();

  const { height = 1, ref } = useResizeObserver();

  const windowHeight = useWindowHeight();

  const stickyStart = (windowHeight - height) / 2;

  return (
    <ClassNames>
      {({ css }) => (
        <Sticky
          enabled={!shouldReduceMotion}
          top={stickyStart}
          bottomBoundary={bottomBoundarySelector}
          innerClass={css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: -1,
          })}
        >
          <Image
            ref={ref}
            src={url}
            mt={60}
            maxWidth={480}
            width="100%"
            mx="auto"
          />
        </Sticky>
      )}
    </ClassNames>
  );
};

const RewardsOverview = props => {
  return (
    <>
      <Box
        display="flex"
        justifyContent={['space-between', 'unset']}
        alignItems="center"
        mx={[20, 'auto']}
        mt={[50, 70]}
      >
        <FeatureWithIconAndText
          text="No credit checks"
          icon={<MagnifyingGlass />}
        />

        <FeatureDivider />

        <FeatureWithIconAndText text="No minimum income" icon={<NoMinimum />} />

        <FeatureDivider />

        <FeatureWithIconAndText text="No hidden fees" icon={<NoHiddenFees />} />
      </Box>

      <StickyCard
        url={props.cardIllustration.asset.url}
        bottomBoundarySelector=".stickycard-end"
      />

      <Box
        className="stickycard-end"
        pb={[0, 40]}
        display="flex"
        flexDirection="column"
      >
        <Text variant={'h400Medium'} mx="auto" mt={[30, 100]}>
          Rewards with style
        </Text>

        <Box
          mx="auto"
          mt={[14, 21]}
          mb={90}
          px={35}
          maxWidth={600}
          textAlign="center"
          fontWeight={700}
          fontSize={30}
          lineHeight="42px"
          letterSpacing="0.2px"
        >
          Rewards on all your purchases. And your friends&apos; purchases.
          Forever.
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection={['column', 'row']}
        alignItems="center"
        mx={['unset', 'auto']}
        width={['initial', '100%', 'initial']}
        px={[0, 60]}
        sx={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box mr={[0, 150]}>
          <Stat num="3x" text="for every TurboBoost dollar you spend" />
          <Stat num="1x" text="for every dollar you spend" />
          <Stat num=".5x" text="for every dollar your friends spend" />
        </Box>

        <Box
          width={['100%', 700]}
          maxWidth={['unset', '60vw', '40vw']}
          maxHeight="100%"
          mb={['-100px', '-150px']}
          mr={['auto', 0]}
          alignSelf="end"
          sx={{
            position: ['unset', 'absolute', 'unset'],
            right: ['unset', 0, 'unset'],
          }}
        >
          <Image
            src={props.screenshot.asset.url}
            sx={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Box>
      </Box>
    </>
  );
};

const StyledSlider = props => {
  return (
    <Box py={20}>
      <Slider
        railStyle={{
          backgroundColor: '#F38E69',
          opacity: 0.11,
          borderRadius: '44px',
          height: '10px',
        }}
        handleStyle={{
          backgroundColor: '#CC6629',
          boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.11)',
          border: 'none',
          width: '24px',
          height: '24px',
          marginTop: '-7px',
        }}
        trackStyle={{
          backgroundColor: '#CC6629',
          borderRadius: '44px',
          height: '10px',
        }}
        {...props}
      />
    </Box>
  );
};

const Card = ({ children, ...rest }) => (
  <Box px={19} py={40} mx={11} bg="white" {...rest}>
    {children}
  </Box>
);

const SliderCard = ({
  label,
  displayedValue,
  transitionDirection,
  sliderProps,
}) => {
  const shouldReduceMotion = useReducedMotion();

  return (
    <Card mb={10} flex={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text variant="h200Medium" sx={{ textTransform: 'uppercase' }}>
          {label}
        </Text>

        <Text variant="h600Bold" color="#CC6629">
          {shouldReduceMotion ? (
            displayedValue
          ) : (
            <TextTransition
              text={displayedValue}
              springConfig={presets.gentle}
              direction={transitionDirection}
            />
          )}
        </Text>
      </Box>
      <StyledSlider {...sliderProps} />
    </Card>
  );
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const RewardsCalculator = props => {
  const [annualIncome, setAnnualIncome] = useState(40000);
  const [numOfReferrals, setNumOfReferrals] = useState(10);

  const cashback = useMemo(() => {
    return currencyFormatter.format(
      estimateCardRewards(annualIncome, numOfReferrals),
    );
  }, [annualIncome, numOfReferrals]);

  const previousAnnualIncome = usePrevious(annualIncome);
  const previousNumOfReferrals = usePrevious(numOfReferrals);

  return (
    <Box bg="#FAF8F6">
      <Box
        fontWeight={[700, 600]}
        fontSize={[30, 42]}
        lineHeight={['42px', '58px']}
        mt={[82, 120]}
        mb={[18, 43]}
        mx="auto"
        px={40}
        textAlign="center"
      >
        See what you&apos;ll earn.
      </Box>

      <Box maxWidth={1020} mx="auto">
        <Box display="flex" flexDirection={['column', 'row']}>
          <SliderCard
            label="Your Annual Income"
            displayedValue={
              annualIncome > 99999
                ? '+$100,000'
                : currencyFormatter.format(annualIncome)
            }
            sliderProps={{
              value: annualIncome,
              min: 20000,
              max: 100000,
              step: 1000,
              onChange: setAnnualIncome,
            }}
            transitionDirection={
              previousAnnualIncome < annualIncome ? 'up' : 'down'
            }
          />

          <SliderCard
            label="Friends Referred"
            displayedValue={numOfReferrals > 99 ? '+99' : numOfReferrals}
            sliderProps={{
              value: numOfReferrals,
              min: 0,
              max: 100,
              onChange: setNumOfReferrals,
            }}
            transitionDirection={
              previousNumOfReferrals < numOfReferrals ? 'up' : 'down'
            }
          />
        </Box>

        <Card textAlign="center" mb={[80, 150]}>
          <Text variant="h600Bold" mb="5px">
            {cashback} cashback/year
          </Text>

          <Text sx={{ textTransform: 'uppercase' }} variant="h200Medium">
            Based on avg. yearly card spending
          </Text>
        </Card>
      </Box>
    </Box>
  );
};

const FeatureWithIconAndTextForPerks = ({ icon, text }) => (
  <FeatureWithIconAndText
    {...{ icon, text }}
    mt={[50, 0]}
    maxWidth={150}
    mx={['auto', 'unset']}
  />
);

const OtherPerks = props => (
  <Box
    backgroundColor="#0D0E14"
    color="white"
    display="flex"
    flexDirection="column"
    alignItems="center"
    textAlign="center"
  >
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 0,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          background:
            'linear-gradient(128.31deg, #06070B 1.41%, #14161F 60.59%)',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: -1,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: -1,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
          transform: 'translateX(25%)',
        }}
      >
        <Image
          css={{
            maxWidth: 2000,
          }}
          src={props.bottomCardIllustration.asset.url}
        />
      </Box>

      <Box
        mt={[110, 230]}
        px={20}
        mx="auto"
        lineHeight="42px"
        fontSize="30px"
        fontWeight={700}
      >
        Premium card, non-premium cost.
      </Box>

      <Box lineHeight="28px" mt={20} px={40} maxWidth={500}>
        This is the card for everyone. We offer premium features at no extra
        cost to you.
      </Box>

      <Box
        display="flex"
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        maxWidth={900}
        px={80}
        width="100%"
        mt={[0, 85]}
      >
        <FeatureWithIconAndTextForPerks
          text="Rewards on all your purchases"
          icon={<PaymentMethod />}
        />

        <FeatureWithIconAndTextForPerks
          text="Rewards on all your friends' purchases"
          icon={<Friends />}
        />

        <FeatureWithIconAndTextForPerks
          text="Build your credit with every purchase"
          icon={<Bars />}
        />
      </Box>

      <Box
        display="flex"
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        alignItems="center"
        maxWidth={740}
        width="100%"
        mx="auto"
        my={150}
      >
        <Box
          px={20}
          lineHeight={['42px', '58px']}
          fontSize={['30px', '42px']}
          fontWeight={[700, 600]}
          maxWidth={300}
          mx={['auto', 'unset']}
        >
          Get infinite rewards.
        </Box>

        <PrimaryButton
          className="vrlps-trigger"
          sx={{
            borderRadius: '4px',
            textTransform: 'uppercase',
            letterSpacing: '1.2px',
            marginTop: [50, 0],
            flexGrow: 0,
          }}
          px={20}
        >
          Join the waitlist
        </PrimaryButton>
      </Box>
    </Box>
  </Box>
);

export function BoostCardDetailsV2(props) {
  return (
    <Box
      color="#26272E"
      fontFamily="Poppins"
      display="flex"
      flexDirection="column"
    >
      <RewardsOverview {...props} />

      <RewardsCalculator {...props} />

      <OtherPerks {...props} />
    </Box>
  );
}
