import { Box, Heading, Image, Text, Flex, Button } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import colors from './themes/colors';
import BottomCircularRect from '../assets/svgs/bottomCircularRect.svg';
import RightArrow from '../assets/svgs/RightArrow.svg';
import { navigate } from '@reach/router';

export function GridPlus(props) {
  return (
    <div style={{ backgroundColor: colors.backgroundPale }}>
      <BottomCircularRect fill={colors.white} />
      <Wrapper mt={100} textAlign="center" pb={100}>
        <Text variant={'h700Medium'} color={colors.teal300}>
          Grid +
        </Text>
        <Text variant={'h820'} mt={25}>
          Unlock tons of great stuff with a Grid+ membership.
        </Text>
        <Text variant={'h500Regular'} mt={15}>
          Get a free tax projection, flexible repayments, a Grid Card and so
          much more with our premium membership.
        </Text>
        <Button
          variant={'primaryClear'}
          mt={3}
          onClick={() => navigate('/pricing/')}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Text
              variant={'h400'}
              color={colors.teal300}
              style={{ marginRight: 5 }}
            >
              Learn More
            </Text>
            <RightArrow fill={colors.teal300} />
          </div>
        </Button>
      </Wrapper>
    </div>
  );
}
