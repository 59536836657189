import * as React from 'react';
import { useEffect, useState } from 'react';
import clientrpc, { TwirpError } from '../api/clientrpc';
import { Heading, Text } from 'rebass';
import { string } from '../copy/Strings.gen';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import Wrapper from './wrapper';

function ConfirmEmail() {
  const location = useLocation();
  const [state, setState] = useState<'LOADING' | 'SUCCESS' | 'FAILED'>();
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    const request = (location.state as {
      request?: { token: string; validationCode: string };
    })?.request;
    if (!request) {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      const validationCode = params.get('validationToken');
      navigate('/confirm-email', {
        replace: true,
        state: {
          request: {
            token,
            validationCode,
          },
        },
      });
      return;
    }
    if (!request.token || !request.validationCode) {
      setState('FAILED');
      setMessage('Something went wrong. Please click the link in your email.');
      return;
    }
    if (state) {
      return;
    }
    setState('LOADING');
    doConfirmEmailRequest(request.token, request.validationCode)
      .then(res => {
        if (res.success) {
          setState('SUCCESS');
        } else {
          setState('FAILED');
          setMessage(res.error);
        }
      })
      .catch(ex => {
        setState('FAILED');
      });
  }, [location.state, state]);

  switch (state) {
    case undefined:
    // fallthrough
    case 'LOADING':
      return <ConfirmEmailResultLoading />;
    case 'SUCCESS':
      return (
        <ConfirmEmailResult
          heading={'Success!'}
          message={'Thanks for confirming your email.'}
        />
      );
    case 'FAILED':
      return (
        <ConfirmEmailResult
          heading={'Uh oh…'}
          message={message ?? 'Something went wrong. Please try again.'}
        />
      );
  }
}

function ConfirmEmailResultLoading() {
  const [waited, setWaited] = useState(false);
  useEffect(() => {
    const handle = setTimeout(() => {
      setWaited(true);
    }, 2000);
    return () => {
      clearTimeout(handle);
    };
  }, []);
  // Only Show the loading state after waiting a beat, in order to avoid a flash of unnecessary content.
  if (!waited) {
    return <ConfirmEmailResult heading={''} message={''} />;
  }
  return (
    <ConfirmEmailResult
      heading={'Loading…'}
      message={'It’s taking a little longer than usual.'}
    />
  );
}

function ConfirmEmailResult({
  heading,
  message,
}: {
  heading: string;
  message: string;
}) {
  return (
    <Wrapper my={100} height={200}>
      <Heading>{heading}</Heading>
      <Text variant={'h400Medium'}>{message}</Text>
    </Wrapper>
  );
}

async function doConfirmEmailRequest(
  token: string,
  validationCode: string,
): Promise<{
  success: boolean;
  error?: string;
}> {
  try {
    await clientrpc.confirmEmail({
      token,
      validationCode,
    });
    return { success: true };
  } catch (ex) {
    if (ex instanceof TwirpError) {
      const error = string({ key: ex.meta?.localized_message });
      return {
        success: false,
        error: error,
      };
    }
    return {
      success: false,
      error: `Something went wrong. Please try again soon or reach out to us if you need help.`,
    };
  }
}

export default ConfirmEmail;
