import React from 'react';
import { Text, Heading } from 'rebass';

const textSerializer = {
  types: {
    block: function Block({ node, children }) {
      const { style = 'normal' } = node;
      switch (style) {
        case 'h1':
          return (
            <Heading variant="h700Medium" my={4}>
              {children}
            </Heading>
          );
        case 'h2':
          return (
            <Heading variant="h600Medium" my={4}>
              {children}
            </Heading>
          );
        case 'h3':
          return (
            <Heading variant="h500Medium" my={4}>
              {children}
            </Heading>
          );
        case 'normal':
          return (
            <Text variant="h500Regular" my={4}>
              {children}
            </Text>
          );
        default:
          console.error(`Unmapped sanity block content node type: ${node}`);
          return <Text>{children}</Text>;
      }
    },
  },
  list: function List({ children, type }) {
    switch (type) {
      case 'number':
        return <ol>{children}</ol>;
      case 'bullet': // fallthrough
      default:
        return <ol>{children}</ol>;
    }
  },
  listItem: function ListItem({ children }) {
    return (
      <li
        css={{
          fontFamily: 'Poppins',
        }}
      >
        <Text variant="h500Regular">{children}</Text>
      </li>
    );
  },
};

export default textSerializer;
