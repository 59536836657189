import { Box, Heading, Image, Text, Flex } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import colors from '../components/themes/colors';
import BottomCircularRect from '../assets/svgs/bottomCircularRect.svg';

// @ts-ignore
import LockMoney from '../assets/images/LockMoney.png';
// @ts-ignore
import FDIC from '../assets/images/FDIC.png';
// @ts-ignore
import CertificateStamp from '../assets/images/CertificateStamp.png';

export function BoostCardSecurityClincher(props) {
  return (
    <>
      <Box backgroundColor={colors.pale200_30_100_opacity}>
        <BottomCircularRect fill={colors.white} />
      </Box>
      <Wrapper bg={colors.pale200_30_100_opacity} pt={100}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            src={LockMoney}
            style={{ overflow: 'hidden', objectFit: 'cover' }}
          />
          <Flex flexGrow="1">
            <Box pt={50}>
              <Heading
                sx={{
                  fontFamily: 'Poppins',
                  fontStyle: 'bold',
                  fontWeight: 700,
                  fontSize: ['30px', '30px', '30px'],
                  lineHeight: ['40px', '40px', '44px'],
                }}
                textAlign="center"
              >
                We’re committed to
              </Heading>
              <Heading
                sx={{
                  fontFamily: 'Poppins',
                  fontStyle: 'bold',
                  fontWeight: 700,
                  fontSize: ['30px', '30px', '30px'],
                  lineHeight: ['40px', '40px', '44px'],
                }}
                textAlign="center"
                mb={30}
              >
                your security.
              </Heading>
            </Box>
          </Flex>

          <Flex
            alignItems="flex-start"
            flexDirection={['column', 'row', 'row']}
            textAlign="center"
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              ml={2}
              mr={2}
            >
              <Flex height={120} justifyContent="center" alignItems="flex-end">
                <Image
                  mt={'5px'}
                  style={{
                    objectFit: 'contain',
                    maxWidth: '100%',
                    maxHeight: '60%',
                    padding: '10%',
                  }}
                  src={FDIC}
                />
              </Flex>
              <Text variant={'h400Bold'} maxWidth={300}>
                Your money is FDIC insured up to $250,000.
              </Text>
            </Flex>

            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              ml={2}
              mr={2}
            >
              <Flex height={120} justifyContent="center" alignItems="flex-end">
                <Image
                  mt={'5px'}
                  style={{
                    objectFit: 'contain',
                    maxWidth: '100%',
                    maxHeight: '60%',
                    padding: '10%',
                  }}
                  src={CertificateStamp}
                />
              </Flex>
              <Text variant={'h400Bold'} maxWidth={300}>
                Your account is protected by bank-grade encryption and
                authentication.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Wrapper>
    </>
  );
}
