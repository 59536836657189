import React, { useState } from 'react';
import { Flex, Button, Box, Text, Image } from 'rebass';
import { Label, Select } from '@rebass/forms';

// @ts-ignore
import plus from '../../assets/images/+.png';
// @ts-ignore
import minus from '../../assets/images/-.png';
import BlockContent from '@sanity/block-content-to-react';
import updatedTextSerializer from '../updatedTextSerializer';
import SwiperSlideComponent from '../swiperSlideComponent';

const UpdatedFrequentlyAskedQuestions = ({ sections }) => {
  // The currently visible section
  const [sectionIdx, setSectionIdx] = useState<number>(0);
  // The currently expanded element
  const [currentExpandedIdx, setCurrentExpandedIdx] = useState<number>(null);

  const currentSection = sections[sectionIdx];
  return (
    <SwiperSlideComponent style={{ overflowY: 'auto' }}>
      <Flex
        bg="#0C003F"
        pt={['37.5%', '25%', '15%']}
        pb="15%"
        minHeight="100dvh"
        sx={{
          background:
            'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
        }}
      >
        <Flex mx={[23, 75, 125]} flexDirection="column" width="100%">
          <Flex mb={['3.5%', '3.5%', '7.5%']}>
            {sections.length > 1 && (
              <>
                <Flex
                  display={['flex', 'flex', 'none']}
                  flexDirection="column"
                  width="100%"
                >
                  <Label
                    htmlFor="category"
                    color="white"
                    sx={{
                      fontFamily: 'heading2',
                      fontWeight: 'extrabold',
                      fontSize: ['34px', '34px', '58px'],
                    }}
                  >
                    Category
                  </Label>
                  <Select
                    name="category"
                    id="category"
                    mt={3}
                    mb={3}
                    color="#1E1E1E"
                    textAlign="center"
                    onChange={evt => {
                      setSectionIdx(evt.target.selectedIndex);
                      setCurrentExpandedIdx(null);
                    }}
                    sx={{
                      borderRadius: ['12.5px', '17.5px', '17.5px'],
                      bg: 'neon',
                      py: ['4px', '4px', '10px'],
                      fontFamily: 'heading2',
                      fontWeight: 'body',
                      fontSize: ['13px', '13px', '18px'],
                      outline: 'none',
                    }}
                  >
                    {sections.map(({ title }) => {
                      return <option key={title}>{title}</option>;
                    })}
                  </Select>
                </Flex>
              </>
            )}
            <Flex
              display={['none', 'none', 'flex']}
              flexWrap={['wrap']}
              width={['0%', '0%', '100%']}
            >
              {sections.map(({ title }, idx) => {
                return (
                  <Button
                    key={title}
                    display={['none', 'none', 'block']}
                    bg={sectionIdx === idx ? '#1E1E1E' : 'neon'}
                    color={sectionIdx === idx ? 'neon' : '#1E1E1E'}
                    mr="2.5%"
                    mb="2.5%"
                    width={['110px', '130px', '165px']}
                    height={'65px'}
                    onClick={() => {
                      setCurrentExpandedIdx(null);
                      setSectionIdx(idx);
                    }}
                    sx={{
                      border:
                        sectionIdx === idx
                          ? '1.5px solid white'
                          : '1.5px solid #0C003F',
                      ':hover': {
                        border: '1.5px solid white',
                        color: 'neon',
                        bg: '#1E1E1E',
                      },
                      borderRadius: ['12.5px', '17.5px', '17.5px'],
                      py: '4px',
                      fontFamily: 'heading2',
                      fontWeight: 'body',
                      fontSize: ['10px', '13px', '18px'],
                      lineHeight: '140%',
                      whiteSpace: 'normal',
                    }}
                  >
                    {title}
                  </Button>
                );
              })}
            </Flex>
          </Flex>
          {/* Separator */}
          <Box height={1.5} bg="neon" />
          <FAQTable
            currentSection={currentSection}
            currentExpandedIdx={currentExpandedIdx}
            setCurrentExpandedIdx={setCurrentExpandedIdx}
          />
        </Flex>
      </Flex>
    </SwiperSlideComponent>
  );
};

const FAQTable = ({
  currentSection,
  currentExpandedIdx,
  setCurrentExpandedIdx,
}) => {
  return (
    <Flex flexDirection="column">
      {currentSection.items.map((item, idx) => {
        return (
          <FAQRow
            key={item._key}
            title={item.title}
            description={item.description}
            visible={idx === currentExpandedIdx}
            setVisible={newVisible => {
              setCurrentExpandedIdx(newVisible ? idx : null);
            }}
            showBottomBorder={idx === currentSection?.items.length - 1}
          />
        );
      })}
    </Flex>
  );
};

const FAQRow = ({
  title,
  description,
  visible,
  setVisible,
  showBottomBorder,
}) => {
  return (
    <>
      <Flex
        overflow="hidden"
        sx={{
          cursor: visible ? 'auto' : 'pointer',
          transition: '0.2s height ease-in-out, 1s background ease-in-out',
        }}
        onClick={() => {
          if (!visible && setVisible) {
            setVisible(!visible);
          }
        }}
      >
        <Flex width="100%" flexDirection="column">
          <Flex justifyContent="space-between">
            <Flex alignItems="center" my="3.5%" width={['50%', 'auto', 'auto']}>
              <Text color="white" variant="subH920" fontWeight="extrabold">
                {title}
              </Text>
            </Flex>
            <Flex
              width="25%"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ zIndex: 100 }}
            >
              <a
                href="#"
                style={{
                  height: '100%',
                  width: '22.5%',
                  display: 'flex',
                  marginRight: '5%',
                }}
                onClick={evt => {
                  evt.preventDefault();
                  setVisible(!visible);
                }}
              >
                {visible ? (
                  <Image
                    src={minus}
                    sx={{
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <Image
                    src={plus}
                    sx={{
                      objectFit: 'contain',
                    }}
                  />
                )}
              </a>
            </Flex>
          </Flex>
          <Box
            maxHeight={visible ? 'auto' : 0}
            sx={{
              transition: '0.4s opacity ease-in-out',
            }}
            opacity={visible ? 1 : 0}
            mt={visible ? -4 : 0}
            color="white"
          >
            <BlockContent
              blocks={description}
              serializers={updatedTextSerializer}
            />
          </Box>
        </Flex>
      </Flex>
      {/* Separator */}
      <Box height={1.5} bg="neon" />
    </>
  );
};

export default UpdatedFrequentlyAskedQuestions;
