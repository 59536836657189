import { Box, Heading, Image, Text, Flex } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import colors from '../components/themes/colors';
import PayboostHeroDevice from '../assets/images/PayboostHeroDevice.png';

export function PayboostHeader(props) {
  return (
    <div style={{ maxWidth: '100vw', overflow: 'hidden' }}>
      <Box className="roundedBottom" bg={colors.black}>
        <Wrapper
          textAlign="center"
          pt={50}
          mb={-2}
          pb={10}
          style={{ overflow: 'hidden' }}
        >
          <Heading
            sx={{
              fontFamily: 'Poppins',
              fontStyle: 'bold',
              fontWeight: 800,
              fontSize: ['42px', '42px', '42px'],
              lineHeight: ['70px', '70px', '44px'],
            }}
            color={colors.white}
            textAlign="center"
            mb={60}
          >
            Grid Tax is easy to get!
          </Heading>
          <Image
            src={PayboostHeroDevice}
            height="505px"
            width="736.5px"
            style={{ objectFit: 'contain', marginBottom: '-15%' }}
          />
        </Wrapper>
      </Box>

      <Heading
        sx={{
          fontFamily: 'Poppins',
          fontStyle: 'bold',
          fontWeight: 800,
          fontSize: ['42px', '42px', '42px'],
        }}
        textAlign="center"
        mt={100}
      >
        Get your tax refund early.
      </Heading>
    </div>
  );
}
