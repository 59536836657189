import { Box, Heading, Text, Flex } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import colors from '../components/themes/colors';

import BottomCircularRect from '../assets/svgs/bottomCircularRect.svg';
import { PaddedRoundedButton } from './button';

export function BoostCardWaitlistClincher(props) {
  return (
    <>
      <Box backgroundColor={colors.black}>
        <BottomCircularRect fill={colors.pale200_30_100_opacity} />
      </Box>
      <Wrapper bg={colors.black} pt={100}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          pb={100}
        >
          <Heading
            sx={{
              fontFamily: 'Poppins',
              fontStyle: 'bold',
              fontWeight: 800,
              fontSize: ['42px', '42px', '42px'],
              lineHeight: ['58px', '58px', '58px'],
              color: colors.white,
              marginBottom: 15,
            }}
            textAlign="center"
          >
            Get started for free.
          </Heading>
          <Text
            variant={'h400Bold'}
            maxWidth={500}
            mb={20}
            color={colors.white}
            textAlign="center"
          >
            The top 10 people on the waitlist will get $5,000 each! Invite your
            friends to move up the list.
          </Text>
          <PaddedRoundedButton
            variant="primary"
            hoverColor="teal400"
            className="vrlps-trigger"
          >
            Join the Waitlist
          </PaddedRoundedButton>
        </Flex>
      </Wrapper>
    </>
  );
}
