import React from 'react';
import { Flex, Text, Box, Image, Heading } from 'rebass';
import Wrapper from './wrapper';
import colors from './themes/colors';
// @ts-ignore
import WomanAndChild from '../assets/images/WomanAndChild.png';

// About us page
export default function AboutUs(props) {
  return (
    <>
      <Box
        className={'headerTransitionTrigger'}
        bg={colors.black}
        style={{ paddingTop: 50 }}
      >
        <Wrapper pb={100}>
          <Box>
            <Text
              variant={'h300Regular'}
              style={{ color: colors.white, opacity: '40%' }}
            >
              ABOUT US
            </Text>
            <Heading
              sx={{
                fontFamily: 'Poppins',
                fontStyle: 'medium',
                fontWeight: 800,
                fontSize: ['42px', '42px', '42px'],
                maxWidth: ['100%', '60%', '60%'],
              }}
              color={colors.white}
            >
              We’re here to help you. We provide financial services to serve
              you, not our bottom line.
            </Heading>
          </Box>
        </Wrapper>
      </Box>
      <Image
        src={WomanAndChild}
        style={{ width: '100%', overflow: 'hidden', objectFit: 'cover' }}
      />
      <Wrapper mt={100} mb={100}>
        <Heading
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 800,
            fontSize: ['42px', '42px', '42px'],
            maxWidth: ['100%', '50%', '50%'],
          }}
        >
          &ldquo;We believe in building financial products and services for
          those that need them most.&rdquo;
        </Heading>

        <Flex flexDirection={['column', 'row', 'row']} mt={38}>
          <Text variant={'h400Regular'}>
            We’re in the business of helping people get the money they need for
            all of life’s ups and downs. After supporting thousands of clients
            on their taxes we realized how frustrating it is to have your tax
            refund locked up with the IRS for a full year. Instead, you could be
            using that money to pay bills, save for retirement or just to treat
            yourself.
          </Text>
          <Text variant={'h400Regular'} mt={[30, 0, 0]}>
            So, we decided to do something about it: We launched Grid and have
            already boosted paychecks for our clients with millions of dollars.
            At Grid we believe in building financial products and services for
            those that need them the most – helping everyone reach their
            financial goals. We look forward to working with you.
          </Text>
        </Flex>
      </Wrapper>
    </>
  );
}
