import React from 'react';
import { Flex, Image, Text } from 'rebass';

const PayboostHeaderPanel = ({
  header,
  description,
  bgColor,
  txtColor,
  imageUrl,
  imageMobileUrl,
}) => {
  return (
    <Flex
      height="100dvh"
      width="100dvw"
      bg={bgColor}
      sx={{
        background:
          'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
    >
      <Flex
        justifyContent={['center', 'center', 'flex-end']}
        alignItems={['center', 'center', 'flex-end']}
        flexDirection={['column', 'column', 'row-reverse']}
        width="100%"
      >
        <Flex
          width={['100%', '100%', '50%']}
          height={['60%', '85%', '100%']}
          mt={['20%', '0%', '0%']}
          mr={['0%', '0%', '0%']}
          justifyContent={['flex-end', 'center', 'flex-start']}
          overflow="hidden"
          sx={{
            position: ['static', 'relative', 'relative'],
          }}
        >
          <Image
            display={['none', 'none', 'flex']}
            src={imageUrl}
            sx={{
              position: 'absolute',
              objectFit: 'contain',
              top: '25%',
            }}
          />
          <Image
            display={['flex', 'none', 'none']}
            src={imageMobileUrl}
            sx={{
              objectFit: 'cover',
              objectPosition: '30% 85%',
            }}
          />
          <Image
            display={['none', 'flex', 'none']}
            src={imageMobileUrl}
            sx={{
              objectFit: 'cover',
              objectPosition: '20% 85%',
            }}
          />
        </Flex>
        <Flex
          flexDirection={'column'}
          justifyContent={'center'}
          ml={[23, 75, 125]}
          mr={[23, 75, 0]}
          width={['', '', '35%']}
          height={['auto', 'auto', 'calc(100% - 105px)']}
          sx={{
            zIndex: 30,
          }}
        >
          <Text variant="h920" color={txtColor}>
            {header}
          </Text>

          <Text variant="subH920" mt={[20, 30, 30]} color={txtColor}>
            {description}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PayboostHeaderPanel;
