import { Box, Card, Flex, Heading, Image, Text } from 'rebass';
import React from 'react';

import Star from '../assets/svgs/Star.svg';
import Wrapper from './wrapper';

export function Testimonials({ testimonials, headerText }) {
  const testimonialPairs = [];
  for (let i = 0; i < testimonials.length; i += 2) {
    const pair = [];
    if (testimonials[i]) {
      pair.push(testimonials[i]);
    }
    if (testimonials[i + 1]) {
      pair.push(testimonials[i + 1]);
    }
    testimonialPairs.push(pair);
  }
  return (
    <Wrapper bg="bgPale">
      <Box py={[80, 80, 140]}>
        <Heading variant="h900" mb={[0, 0, 87]}>
          {headerText}
        </Heading>
        {testimonialPairs.map((pair, i) => (
          <Box bg="white" pb="50px" key={'testimonial-' + i}>
            <TestimonialSlide testimonials={pair} />
          </Box>
        ))}
      </Box>
    </Wrapper>
  );
}

const TestimonialSlide = ({ testimonials }) => (
  <Flex flexDirection={['column', 'column', 'row']} bg="white" height="100%">
    {testimonials.map(testimonial => (
      <Testimonial testimonial={testimonial} key={testimonial._key} />
    ))}
  </Flex>
);

const Testimonial = ({ testimonial }) => (
  <Card my={24} mx={[0, 0, 1]} sx={{ overflow: 'visible' }}>
    <Text
      as="blockquote"
      mb={11}
      sx={{
        textAlign: 'left',
        fontFamily: 'Poppins',
        fontStyle: 'italic',
        fontWeight: 'normal',
        fontSize: '22px',
        lineHeight: '38px',
        letterSpacing: '0.2px',
        '&::before': {
          content: '"“\0a0"',
        },
        '&::after': {
          content: '"\0a0”"',
        },
      }}
    >
      {`${testimonial && testimonial.quotation}`}
    </Text>
    <Box>
      <Flex>
        <Box mt="auto" mr={[10, 10, 13]}>
          <Image
            maxHeight={70}
            maxWidth={70}
            src={testimonial.avatar.asset.url}
          />
        </Box>
        <Flex flexDirection="column">
          <Text variant="h400Medium">{testimonial.clientName}</Text>
          <Flex>
            {Array.from(Array(testimonial.rating).keys()).map(item => (
              <Box key={item}>
                <Star />
              </Box>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  </Card>
);
