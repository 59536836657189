import React from 'react';
import { Flex, Text, Image } from 'rebass';

const AboutHeaderPanel = ({
  header,
  bgColor,
  txtColor,
  imageUrl,
  imageMediumUrl,
  imageMobileUrl,
}) => {
  return (
    <Flex
      height="100dvh"
      width="100dvw"
      bg={bgColor}
      sx={{
        background:
          'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
    >
      <Flex
        justifyContent={['flex-start', 'flex-start', 'flex-end']}
        alignItems={['flex-start', 'flex-start', 'flex-end']}
        flexDirection={['column', 'column', 'row-reverse']}
        width={'100%'}
      >
        <Flex
          width={['100%', '100%', '50%']}
          height={['60%', '80%', 'auto']}
          mb={['12.5%', '7.5%', '0%']}
          justifyContent={['flex-end', 'flex-end', 'flex-end']}
          overflow="hidden"
          sx={{
            position: ['static', 'static', 'absolute'],
            right: 0,
            top: 0,
          }}
        >
          <Image
            display={['none', 'none', 'flex']}
            src={imageUrl}
            sx={{
              objectFit: 'contain',
            }}
          />
          <Image
            display={['flex', 'none', 'none']}
            src={imageMobileUrl}
            sx={{
              objectFit: 'cover',
            }}
          />
          <Image
            display={['none', 'flex', 'none']}
            src={imageMediumUrl || imageMobileUrl}
            sx={{
              objectFit: 'cover',
            }}
          />
        </Flex>
        <Flex
          flexDirection={'column'}
          justifyContent={'center'}
          ml={[23, 75, 125]}
          mr={[23, 75, 0]}
          width={['', '', '40%']}
          height={['auto', 'auto', 'calc(100% - 105px)']}
          sx={{
            zIndex: 30,
          }}
        >
          <Text variant="h920" color={txtColor}>
            {header}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AboutHeaderPanel;
