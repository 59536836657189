const debitCardSpend = 0.5;
const ccApplicable = 0.8;
const avgCashback = 0.0125;

const estimateCardRewards = (annualSpending, numOfReferrals) => {
  const avgMonthlyPay = (annualSpending / 12) * 0.75;

  let annualReferralCashback = 0;

  for (let i = 1; i <= numOfReferrals; i++) {
    let percentage = 0;
    if (i < 6) {
      percentage = 0.002;
    } else if (i < 11) {
      percentage = 0.003;
    } else if (i < 21) {
      percentage = 0.004;
    } else {
      percentage = 0.005;
    }

    annualReferralCashback +=
      percentage * avgMonthlyPay * debitCardSpend * ccApplicable * 12;
  }

  const annualSpendingCashback =
    avgMonthlyPay * debitCardSpend * ccApplicable * avgCashback * 12;

  return annualSpendingCashback + annualReferralCashback;
};

export default estimateCardRewards;
