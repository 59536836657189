import { Flex } from 'rebass';
import ConfirmEmail from '../components/confirmEmail';
import FinishForgotPassword from '../components/finishForgotPassword';
import { graphql } from 'gatsby';
import { useMatch } from '@reach/router';
import BeatLoader from 'react-spinners/BeatLoader';
import React, { useState, useEffect } from 'react';
import * as ReactModal from 'react-modal';

import { Footer } from '../components/footer';
import { FrequentlyAskedQuestions } from '../components/frequentlyAskedQuestions.tsx';
import { Hero } from '../components/hero';
import { HowItWorks } from '../components/howItWorks';
import { Intro } from '../components/intro';
import { ReferralWebPickup } from '../components/referralWebPickup';
import { Testimonials } from '../components/testimonials';
import { Tutorial } from '../components/tutorial';
import { useReferral } from '../data/useReferral';
import AboutUs from '../components/aboutUs';
import AndroidOverlay from '../components/overlays/androidOverlay';
import Clincher from '../components/clincher';
import EligibilityOverlay from '../components/overlays/eligibilityOverlay';
import EligibilityPayboost from '../components/overlays/eligibilityPayboost';
import EligibilityQuestionnaire from '../components/overlays/eligibilityQuestionnaire';
import EligibilityWaitlist from '../components/overlays/eligibilityWaitlist';
import { BulletedDescription } from '../components/bulletedDescription';
import { GridPlus } from '../components/gridPlus';
import Layout from '../components/layout';
import UpdatedLegalDocument from '../components/updated-pages/updatedLegalDocument';
import SEO from '../components/seo';
import TextWall from '../components/textWall';
import { RatingsLarge } from '../components/ratingsLarge';
import OurTeam from '../components/ourTeam';
import { ProductBlurb } from '../components/productBlurb';
import { PayboostClincher } from '../components/payboostClincher';
import { PayboostHeader } from '../components/payboostHeader';
import { Pricing } from '../components/pricing';
import { TryPayboost } from '../components/tryPayboost';
import { ReviewClincher } from '../components/reviewClincher';
import { ValuePropModule } from '../components/valuePropModule';
import { BoostCardSecurityClincher } from '../components/boostCardSecurityClincher';
import { BoostCardWaitlistClincher } from '../components/boostCardWaitlistClincher';
import { BoostCardPromo } from '../components/boostCardPromo';
import { BoostCardHeroV2 } from '../components/boostCardHeroV2';
import { BoostCardDetailsV2 } from '../components/boostCardDetailsV2';
import { CenteredLinkIndex } from '../components/centeredLinkIndex';
import SwiperSlideComponent from '../components/swiperSlideComponent';
import UpdatedAboutUs from '../components/updated-pages/updatedAboutUs';
import { PagePanel } from '../components/page-panels/pagePanel';
import SwiperSlideContainer from '../components/swiperSlideContainer';
import UpdatedPricing from '../components/updated-pages/updatedPricing';
import UpdatedFrequentlyAskedQuestions from '../components/updated-pages/updatedFrequentlyAskedQuestions';

// Enable this next code block to use clientrpcs in the console

// import ClientRPC from "../api/clientrpc";
//
// if(window) {
//     window.dev_api = ClientRPC;
// }

export const query = graphql`
  query PageQuery($page: String!) {
    page: sanityPage(id: { eq: $page }) {
      id
      title
      hasWildcardSubPages
      menu {
        links {
          ... on SanityExternalLink {
            _key
            _type
            text
            url
          }
          ... on SanityInternalLink {
            _key
            _type
            text
            page {
              id
              slug {
                current
              }
            }
          }
          ... on SanityCategory {
            _key
            _type
            name
            items {
              ... on SanityExternalLink {
                _key
                _type
                text
                url
              }
              ... on SanityInternalLink {
                _key
                _type
                text
                page {
                  id
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      }
      isLegacy
      useSwipeScrolling
      navbarCTA
      slug {
        current
      }

      modules {
        ... on SanityTutorial {
          id
          _type
          title
          steps {
            title
            subtitle
            accentColor
            screenshot {
              asset {
                url
              }
            }
          }
        }
        ... on SanityHero {
          id
          _type
          text
          subtext
          ctaStyle
          illustration {
            asset {
              description
              path
              url
              title
            }
          }
          illustrationMobile {
            asset {
              description
              path
              url
              title
            }
          }
          ctaStyle
        }
        ... on SanityBulletedDescription {
          id
          _type
          image {
            asset {
              url
            }
          }
          headline
          subtext
          direction
          bullets {
            content
            bulletColor
          }
        }
        ... on SanityCenteredLinkIndex {
          id
          _type
          listTitle
          centeredLinkItems {
            link
            displayTitle
          }
        }
        ... on SanityGridPlus {
          id
          _type
        }
        ... on SanityRatingsLarge {
          id
          _type
        }
        ... on SanityOurTeam {
          id
          _type
          teammates {
            name
            role
            image {
              asset {
                description
                path
                url
                title
              }
            }
          }
        }
        ... on SanityValuePropsModule {
          id
          _type
          valueProps {
            image {
              asset {
                description
                path
                url
                title
              }
            }
            title
            description
          }
        }
        ... on SanityProductBlurb {
          id
          _type
          title
          blurb
          image {
            asset {
              description
              path
              url
              title
            }
          }
        }
        ... on SanityPayboostClincher {
          id
          _type
        }
        ... on SanityPayboostHeader {
          id
          _type
        }
        ... on SanityReviewClincher {
          id
          _type
          topColor
        }
        ... on SanityPricing {
          id
          _type
          isLegacy
          features {
            ... on SanityFeature {
              title
              description
              isFree
              isPlus
              withSeparator
            }
          }
        }
        ... on SanityTryPayboost {
          id
          _type
        }
        ... on SanityBoostCardSecurityClincher {
          id
          _type
        }
        ... on SanityBoostCardWaitlistClincher {
          id
          _type
        }
        ... on SanityBoostCardPromo {
          id
          _type
        }
        ... on SanityIntro {
          id
          _type
          title
          subtitle
          illustration {
            asset {
              description
              path
              url
              title
            }
          }
        }
        ... on SanityAboutUs {
          id
          _type
          title
          isLegacy
          headerImage {
            asset {
              description
              path
              url
              title
            }
          }
          headerImageMobile {
            asset {
              description
              path
              url
              title
            }
          }
          _rawQuotation(resolveReferences: { maxDepth: 10 })
          _rawDescription(resolveReferences: { maxDepth: 10 })
          aboutUsPanels {
            ... on SanityPagePanel {
              _type
              panelType
              header
              description
              buttonText
              bgColor
              txtColor
              image {
                asset {
                  url
                }
              }
              imageMobile {
                asset {
                  url
                }
              }
              multImages {
                ... on SanityPicture {
                  image {
                    asset {
                      url
                    }
                  }
                  caption
                }
              }
            }
          }
        }

        ... on SanityHowItWorks {
          id
          _type
          _key
          title
          _rawItems(resolveReferences: { maxDepth: 10 })
        }

        ... on SanityTestimonialSet {
          id
          _type
          headerText
          testimonials {
            _key
            clientName
            quotation
            rating
            avatar {
              asset {
                url
              }
            }
          }
        }
        ... on SanityLegalDocument {
          id
          _type
          lastUpdated
          textBlocks: _rawText(resolveReferences: { maxDepth: 10 })
          markdown
        }
        ... on SanityTextWall {
          id
          _type
          textBlocks: _rawText(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityClincher {
          id
          _type
          text
          cta
          ctaLabel
          legal
          screenshot {
            asset {
              url
            }
          }
        }
        ... on SanityFrequentlyAskedQuestionsSet {
          id
          _type
          title
          isLegacy
          sections: _rawSections
        }

        ... on SanityReferralLanding {
          id
          _type
          slug {
            current
          }
        }

        ... on SanityBoostCardHeroV2 {
          id
          _type
          illustration {
            asset {
              url
            }
          }
          ctaText
          header
          subtext
          suffixes
        }

        ... on SanityBoostCardDetailsV2 {
          id
          _type

          cardIllustration {
            asset {
              url
            }
          }

          screenshot {
            asset {
              url
            }
          }

          bottomCardIllustration {
            asset {
              url
            }
          }
        }

        ... on SanityConfirmEmail {
          id
          _type
        }

        ... on SanityPagePanel {
          id
          _type
          panelType
          header
          description
          buttonText
          bgColor
          txtColor
          image {
            asset {
              url
            }
          }
          imageMobile {
            asset {
              url
            }
          }
          multImages {
            ... on SanityPicture {
              _key
              _type
              image {
                asset {
                  url
                }
              }
              caption
            }
          }
        }

        ... on SanityFinishForgotPassword {
          id
          _type
        }
      }
      footer {
        isLegacy
        leftLinksTitle
        leftLinks {
          ... on SanityExternalLink {
            _key
            _type
            text
            url
          }
          ... on SanityInternalLink {
            _key
            _type
            text
            page {
              id
              slug {
                current
              }
            }
          }
        }
        midLinksTitle
        midLinks {
          ... on SanityExternalLink {
            _key
            _type
            text
            url
          }
          ... on SanityInternalLink {
            _key
            _type
            text
            page {
              id
              slug {
                current
              }
            }
          }
        }
        rightLinksTitle
        rightLinks {
          ... on SanityExternalLink {
            _key
            _type
            text
            url
          }
          ... on SanityInternalLink {
            _key
            _type
            _key
            text
            page {
              id
              slug {
                current
              }
            }
          }
        }
        footerActions {
          _key
          _type
          footerActionTitle
          footerAction
        }
      }
    }
  }
`;

function usePageData() {
  const referralPath = useMatch('/r/:code');
  const [referral, referralIsLoading] = useReferral(referralPath?.code);

  const data = {
    referral: referralPath
      ? { code: referralPath.code, referral: referral }
      : undefined,
  };

  return [data, referralIsLoading];
}

function Page(props) {
  useEffect(() => {
    // Resolves a warning and improves accessibility. Not functional for most users.
    ReactModal.setAppElement('#___gatsby');
  }, []);

  // Client-side page data.
  const [data, dataIsLoading] = usePageData();

  // Loading Spinner (only shows if data loading is slow).
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  useEffect(() => {
    if (!dataIsLoading) {
      setShowLoadingSpinner(false);
    }

    const handle = setTimeout(() => {
      setShowLoadingSpinner(true);
    }, 1500);
    return () => {
      clearTimeout(handle);
    };
  }, [dataIsLoading, setShowLoadingSpinner]);

  if (dataIsLoading) {
    if (showLoadingSpinner) {
      return (
        <Layout page={props.data.page}>
          <Flex justifyContent="center" alignItems="center" py={'20vh'}>
            <BeatLoader color={'#05C890'} />
          </Flex>
        </Layout>
      );
    }

    return null;
  }

  return (
    <>
      <Layout page={props.data.page}>
        <SEO title={props.data.page.title} />
        {props.data.page.useSwipeScrolling ? (
          <Flex width="100dvw" height="100dvh">
            <SwiperSlideContainer
              className="mySwiper"
              direction="vertical"
              speed="600"
              slides-per-view="auto"
              space-between="0"
              mousewheel="true"
              mousewheel-threshold-delta="40"
              mousewheel-sensitivity="0.5"
              mousewheel-force-to-axis="true"
              simulate-touch="false"
              prevent-interaction-on-transition="true"
              // init="false"
            >
              <Modules modules={props.data.page.modules} data={data} />
              {props.data.page.footer && <Footer {...props.data.page.footer} />}
            </SwiperSlideContainer>
          </Flex>
        ) : (
          <>
            <Modules modules={props.data.page.modules} data={data} />
            {props.data.page.footer && <Footer {...props.data.page.footer} />}
          </>
        )}

        <EligibilityOverlay />
        <EligibilityQuestionnaire />
        <EligibilityPayboost />
        <EligibilityWaitlist />
        <AndroidOverlay />
      </Layout>
    </>
  );
}

function Modules({ modules, data }) {
  return modules.map(mod => {
    if (!mod) {
      return null;
    }

    switch (mod._type) {
      case 'hero':
        return (
          <Hero
            key={mod.id}
            imageStyle={{ transform: 'translate(10px, 150px)' }}
            {...mod}
          />
        );
      case 'intro':
        return <Intro key={mod.id} {...mod} />;
      case 'tutorial':
        return <Tutorial key={mod.id} {...mod} />;
      case 'testimonialSet':
        return <Testimonials key={mod.id} {...mod} />;
      case 'clincher':
        return <Clincher key={mod.id} {...mod} />;
      case 'legalDocument':
        return <UpdatedLegalDocument key={mod.id} {...mod} />;
      case 'textWall':
        return <TextWall key={mod.id} {...mod} />;
      case 'frequentlyAskedQuestionsSet':
        return mod.isLegacy ? (
          <FrequentlyAskedQuestions key={mod.id} {...mod} />
        ) : (
          <UpdatedFrequentlyAskedQuestions key={mod.id} {...mod} />
        );
      case 'aboutUs':
        return mod.isLegacy ? (
          <AboutUs
            key={mod.id}
            {...mod}
            quotation={mod._rawQuotation}
            description={mod._rawDescription}
          />
        ) : (
          <UpdatedAboutUs key={mod.id} {...mod} />
        );
      case 'howItWorks':
        return <HowItWorks key={mod.id} {...mod} items={mod._rawItems} />;
      case 'referralLanding':
        return <ReferralWebPickup key={mod.id} data={data.referral} {...mod} />;
      case 'bulletedDescription':
        return <BulletedDescription key={mod.id} {...mod} />;
      case 'gridPlus':
        return <GridPlus key={mod.id} {...mod} />;
      case 'ratingsLarge':
        return <RatingsLarge key={mod.id} {...mod} />;
      case 'ourTeam':
        return <OurTeam key={mod.id} {...mod} />;
      case 'productBlurb':
        return <ProductBlurb key={mod.id} {...mod} />;
      case 'payboostClincher':
        return <PayboostClincher key={mod.id} {...mod} />;
      case 'payboostHeader':
        return <PayboostHeader key={mod.id} {...mod} />;
      case 'pricing':
        return mod.isLegacy ? (
          <Pricing key={mod.id} {...mod} />
        ) : (
          <UpdatedPricing key={mod.id} {...mod} />
        );
      case 'tryPayboost':
        return <TryPayboost key={mod.id} {...mod} />;
      case 'reviewClincher':
        return <ReviewClincher key={mod.id} {...mod} />;
      case 'valuePropsModule':
        return <ValuePropModule key={mod.id} {...mod} />;
      case 'boostCardSecurityClincher':
        return <BoostCardSecurityClincher key={mod.id} {...mod} />;
      case 'boostCardWaitlistClincher':
        return <BoostCardWaitlistClincher key={mod.id} {...mod} />;
      case 'boostCardPromo':
        return <BoostCardPromo key={mod.id} {...mod} />;
      case 'boostCardHeroV2':
        return <BoostCardHeroV2 key={mod.id} {...mod} />;
      case 'boostCardDetailsV2':
        return <BoostCardDetailsV2 key={mod.id} {...mod} />;
      case 'centeredLinkIndex':
        return <CenteredLinkIndex key={mod.id} {...mod} />;
      case 'confirmEmail':
        return <ConfirmEmail key={mod.id} {...mod} />;
      case 'finishForgotPassword':
        return <FinishForgotPassword key={mod.id} {...mod} />;
      case 'pagePanel':
        return (
          <SwiperSlideComponent key={mod.id}>
            <PagePanel {...mod} />
          </SwiperSlideComponent>
        );
      default:
        return null;
    }
  });
}

export default Page;
