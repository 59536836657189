import { Box, Heading, Image, Text, Flex } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';

export function Intro(props) {
  return (
    <Wrapper className={'headerTransitionTrigger'} bg="#FFFFFF">
      <Box pt={210} pb={74}>
        <Flex flexDirection={['column', 'column', 'row']}>
          <Box flex={1}>
            <Image src={props.illustration.asset.url} />
          </Box>
          <Box flex={1}>
            <Heading variant="text.h900">{props.title}</Heading>
            <Text
              mt={40}
              sx={{
                fontFamily: 'heading',
                fontWeight: 'normal',
                lineHeight: ['26px', '32px'],
                fontSize: ['18px', '20px'],
                color: 'black',
                letterSpacing: '0.2px',
              }}
            >
              {props.subtitle}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Wrapper>
  );
}
