import React from 'react';
import { Flex, Image } from 'rebass';
import LandingHeaderPanel from './header-page-panels/landingHeaderPanel';
import AboutHeaderPanel from './header-page-panels/aboutHeaderPanel';
import LandingCardPanel from './landingCardPanel';
import FlexPagePanel from './flexPagePanel';
import AboutReviewsPanel from './aboutReviewsPanel';
import PayboostHeaderPanel from './header-page-panels/payboostHeaderPanel';
import CardHeaderPanel from './header-page-panels/cardHeaderPanel';
import LandingSecurityPanel from './landingSecurityPanel';
import TosHeaderPanel from './header-page-panels/tosHeaderPanel';

export type PagePanelProps = {
  header?: string;
  description?: string;
  panelType?: string;
  buttonText?: string;
  bgColor: string;
  txtColor: string;
  image?: Image;
  imageMobile?: Image;
  reviews?: string[];
  multImages?: Array<ImageObj>;
};

export type ImageObj = {
  image: Image;
  caption: string;
};

export type Image = {
  asset: {
    url: string;
  };
};

export function PagePanel({
  header,
  description,
  panelType,
  buttonText,
  bgColor,
  txtColor,
  image,
  imageMobile,
  reviews,
  multImages,
}: PagePanelProps) {
  const imageUrl: string | null = image ? image.asset.url : null;
  const imageMobileUrl: string | null = imageMobile
    ? imageMobile.asset.url
    : null;
  const imageMediumUrl: string | null =
    multImages && multImages.length > 0 ? multImages[0].image.asset.url : null;
  return (
    <Flex>
      {(() => {
        switch (panelType) {
          case 'LANDING_HEADER':
            return (
              <LandingHeaderPanel
                header={header}
                bgColor={bgColor}
                description={description}
                txtColor={txtColor}
                imageUrl={imageUrl}
                imageMediumUrl={imageMediumUrl}
                imageMobileUrl={imageMobileUrl}
              />
            );
          case 'ABOUT_HEADER':
            return (
              <AboutHeaderPanel
                header={header}
                bgColor={bgColor}
                txtColor={txtColor}
                imageUrl={imageUrl}
                imageMobileUrl={imageMobileUrl}
                imageMediumUrl={imageMediumUrl}
              />
            );
          case 'LANDING_CARD':
            return (
              <LandingCardPanel
                header={header}
                description={description}
                bgColor={bgColor}
                txtColor={txtColor}
                imageUrl={imageUrl}
                imageMediumUrl={imageMediumUrl}
                imageMobileUrl={imageMobileUrl}
                buttonText={buttonText}
              />
            );
          case 'LANDING_SECURITY':
            return (
              <LandingSecurityPanel
                header={header}
                description={description}
                bgColor={bgColor}
                txtColor={txtColor}
                imageUrl={imageUrl}
                imageMediumUrl={imageMediumUrl}
                imageMobileUrl={imageMobileUrl}
              />
            );
          case 'ABOUT_REVIEWS':
            return (
              <AboutReviewsPanel
                reviews={reviews}
                header={header}
                description={description}
                bgColor={bgColor}
                txtColor={txtColor}
                imageUrl={imageUrl}
                imageMobileUrl={imageMobileUrl}
              />
            );
          case 'PAYBOOST_HEADER':
            return (
              <PayboostHeaderPanel
                header={header}
                description={description}
                bgColor={bgColor}
                txtColor={txtColor}
                imageUrl={imageUrl}
                imageMobileUrl={imageMobileUrl}
              />
            );
          case 'CARD_HEADER':
            return (
              <CardHeaderPanel
                header={header}
                description={description}
                bgColor={bgColor}
                txtColor={txtColor}
                imageUrl={imageUrl}
                imageMobileUrl={imageMobileUrl}
              />
            );
          case 'TOS_HEADER':
            return (
              <TosHeaderPanel
                header={header}
                bgColor={bgColor}
                txtColor={txtColor}
                imageUrl={imageUrl}
                imageMobileUrl={imageMobileUrl}
                buttonText={buttonText}
              />
            );
          default:
            return (
              <FlexPagePanel
                header={header}
                description={description}
                bgColor={bgColor}
                txtColor={txtColor}
                imageUrl={imageUrl}
                imageMobileUrl={imageMobileUrl}
                imageMediumUrl={imageMediumUrl}
                panelType={panelType}
                buttonText={buttonText}
              />
            );
        }
      })()}
    </Flex>
  );
}
