import { Flex, Image, Text } from 'rebass';
import SwiperSlideContainer from '../swiperSlideContainer';
import SwiperSlideComponent from '../swiperSlideComponent';
import React from 'react';

export const APP_STORE_REVIEWS =
  'https://apps.apple.com/us/app/grid-paycheck-boost/id1473180914?see-all=reviews';

const AboutReviewsSection = ({
  txtColor,
  reviews,
  imageUrl,
  imageMobileUrl,
}: ReviewsCarouselProps) => {
  return (
    <>
      <Flex flexDirection="column" justifyContent="center">
        <ReviewsCarousel
          txtColor={txtColor}
          reviews={reviews}
          imageUrl={imageUrl}
          imageMobileUrl={imageMobileUrl}
        />
      </Flex>
    </>
  );
};

type ReviewsCarouselProps = {
  reviews: string[];
  imageUrl: string;
  txtColor: string;
  imageMobileUrl: string;
};

const ReviewsCarousel = ({
  reviews,
  imageUrl,
  txtColor,
  imageMobileUrl,
}: ReviewsCarouselProps) => {
  return (
    <Flex
      height={['80%', '65%', '50%']}
      overflowY={['visible']}
      sx={{ position: 'relative' }}
    >
      <Image
        src={imageUrl}
        width={['37.5%', '20%', '30%']}
        sx={{
          position: 'absolute',
          right: ['-10%', '0%', '-6%'],
          top: ['-17.5%', '-7.5%', '-20%'],
          objectFit: 'contain',
        }}
      />
      <SwiperSlideContainer
        className="mySwiper"
        slides-per-view={1}
        navigation="true"
        loop="true"
      >
        {reviews.map((review, i) => {
          const [reviewPg, reviewer] = review.split('\n\n');
          return (
            <SwiperSlideComponent key={i}>
              <Flex justifyContent="center" alignItems="center" height="100%">
                <Flex
                  justifyContent="center"
                  flexDirection="column"
                  height="100%"
                  width="80%"
                  fontSize={['9.5px', '13px', '15px']}
                  lineHeight={'140%'}
                  px={['2.5%', '5%', '5%']}
                  pt="5%"
                  pb="2.5%"
                  color={txtColor}
                  fontWeight="body"
                  fontFamily="heading"
                  sx={{
                    background:
                      'linear-gradient(180deg, rgba(140, 82, 255, 0.2) 0%, rgba(236, 232, 233, 0.2) 100%), rgba(236, 232, 233, 0.2)',
                    border: '1.5px solid white',
                    borderRadius: '30px',
                    backdropFilter: 'blur(6px)',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <Flex
                    flexDirection="column"
                    height={['90%', '85%', '85%']}
                    justifyContent="center"
                  >
                    <Text>{reviewPg}</Text>
                    <br />
                    <Text fontWeight="extrabold">{reviewer}</Text>
                  </Flex>
                  <Flex height={['10%', '15%', '15%']}>
                    <Image
                      width={['35%', '35%', '35%']}
                      src={imageMobileUrl}
                      sx={{
                        objectFit: 'contain',
                      }}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </SwiperSlideComponent>
          );
        })}
      </SwiperSlideContainer>
    </Flex>
  );
};

export default AboutReviewsSection;
