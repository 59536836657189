import React from 'react';
import { Text, Heading } from 'rebass';

const updatedTextSerializer = {
  types: {
    block: function Block({ node, children }) {
      const { style = 'normal' } = node;
      switch (style) {
        case 'h1':
          return (
            <Heading color="inherit" variant="h920" my={4}>
              {children}
            </Heading>
          );
        case 'h2':
          return (
            <Heading color="inherit" variant="h920" my={4}>
              {children}
            </Heading>
          );
        case 'h3':
          return (
            <Heading color="inherit" variant="h920" my={4}>
              {children}
            </Heading>
          );
        case 'normal':
          return (
            <Text color="inherit" variant="subH920" my={4}>
              {children}
            </Text>
          );
        default:
          console.error(`Unmapped sanity block content node type: ${node}`);
          return <Text color="inherit">{children}</Text>;
      }
    },
  },
  list: function List({ children, type }) {
    switch (type) {
      case 'number':
        return <ol>{children}</ol>;
      case 'bullet':
        return <ul>{children}</ul>;
      default:
        return <ol>{children}</ol>;
    }
  },
  listItem: function ListItem({ children }) {
    return (
      <li
        className="list-item"
        style={{
          color: 'inherit',
          fontFamily: 'nimbus-sans-extended',
        }}
      >
        <Text color="inherit" variant="subH920">
          {children}
        </Text>
      </li>
    );
  },
};

export default updatedTextSerializer;
