import React from 'react';
import { Flex, Text, Box, Image } from 'rebass';
import Wrapper from './wrapper';
import colors from './themes/colors';
import { BulletCheck } from '../assets/svgs/BulletCheck';
import { PrimaryButton } from './button';
import { navigate } from '@reach/router';

const BulletRow = props => {
  const p = props.props;
  return (
    <Box display="flex" flexDirection="row" mt={'20px'} mb={'20px'}>
      <div style={{ width: '28px', height: '28px' }}>
        <BulletCheck stroke={colors.white} fill={p.bulletColor} />
      </div>
      <Text ml={'15px'} variant={'h400Regular'}>
        {p.content}
      </Text>
    </Box>
  );
};

const LTR_DIRECTION = 'BULLET_DIRECTION_LTR';
const RTL_DIRECTION = 'BULLET_DIRECTION_RTL';

// About us page
export function BulletedDescription(props) {
  return (
    <Wrapper mt={100}>
      <Box
        display="flex"
        flexDirection={[
          'column',
          props.direction == LTR_DIRECTION ? 'row' : 'row-reverse',
          props.direction == LTR_DIRECTION ? 'row' : 'row-reverse',
        ]}
      >
        <Image style={{ objectFit: 'contain' }} src={props.image.asset.url} />
        <Box ml={[0, 100, 100]}>
          <Text variant={'h820'}>{props.headline}</Text>
          <Text variant={'h500Regular'} mt={10} mb={20}>
            {props.subtext}
          </Text>
          {props.bullets.map(bullet => {
            return <BulletRow key={bullet} props={bullet} />;
          })}

          {props.subtext.includes('PayBoost') && (
            <PrimaryButton
              bg={colors.violet300}
              hoverColor={colors.violet200}
              activeColor={colors.violet200}
              onClick={() => {
                navigate('/payboost/');
              }}
            >
              How it works
            </PrimaryButton>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
}
