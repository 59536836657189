import React, { useState } from 'react';
import { Flex, Heading, Box, Image, Text } from 'rebass';
// @ts-ignore
import Congratulations from '../../assets/images/Congratulations.png';
import { AppleAppStoreButton } from '../appStoreButtons';
import { useLocation } from '@reach/router';
import { PrimaryButton } from '../button';
import { visor } from '../../protos';
import clientrpc from '../../api/clientrpc';
import { toast } from 'react-toastify';
import { serverErrorToast } from '../toaster';

const Payboost = () => {
  const location: any = useLocation();
  const [submitting, setSubmitting] = useState(false);
  return (
    <Flex flexDirection={['column', 'column', 'row']}>
      <Box flex={1}>
        <Flex flexDirection={'column'}>
          <Image src={Congratulations} width={'121px'} height={'126px'} />
          <Heading
            sx={{
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: ['28px', '28px', '50px'],
              lineHeight: ['40px', '40px', '75px'],
              letterSpacing: ['0.2px', '0.2px', '-0.03em'],
            }}
          >
            Get excited for Payboost! Download the app.
          </Heading>
          <Box mt={'10px'}>
            <AppleAppStoreButton context={'OVERLAY'} />
          </Box>
        </Flex>
      </Box>
      <Box flex={1} pt={[30, 30, 144]}>
        <Text variant={'h400Medium'}>Didn’t receive the email yet?</Text>
        <Box mt={'24px'}>
          <PrimaryButton
            loading={submitting}
            onClick={async () => {
              setSubmitting(true);
              const email = location.state?.overlay?.data?.eligibility?.email;
              const req = new visor.clientrpc.SendLeadDownloadLinkRequest();
              req.email = email;
              try {
                await clientrpc.sendLeadDownloadLink(req);
                toast.success('Email sent!');
              } catch {
                serverErrorToast();
              } finally {
                setSubmitting(false);
              }
            }}
          >
            Resend email
          </PrimaryButton>
        </Box>
      </Box>
    </Flex>
  );
};

export default Payboost;
