import Wrapper from './wrapper';
import { Flex, Text, Box, Image } from 'rebass';
import { Link } from 'gatsby';
import React from 'react';

export function CenteredLinkIndex(props) {
  return (
    <Wrapper mb={500}>
      <Box display="flex" flexDirection={['column']}>
        <h2 style={{ marginBottom: 50, marginTop: 35 }}>{props.listTitle}</h2>
        {props.centeredLinkItems.map((p, index) => (
          <Link to={p.link} key={index}>
            <h4>{p.displayTitle}</h4>
          </Link>
        ))}
      </Box>
    </Wrapper>
  );
}
