import { Box, Heading, Image, Text, Flex } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import colors from '../components/themes/colors';

import CarolBaskin from '../assets/images/CarolBaskin.png';
import BottomCircularRect from '../assets/svgs/bottomCircularRect.svg';
import { AppDownloadButtons } from './appStoreButtons';
import { ReviewBubble } from './reviewClincher';

import { SMS_LEAD_GEN_WITH_APP_STORE_DOWNLOAD_BUTTONS } from '../components/cta';

import ReviewerOne from '../assets/images/ReviewerOne.png';
import ReviewerTwo from '../assets/images/ReviewerTwo.png';
import { navigate } from '@reach/router';

export function RatingsLarge(props) {
  return (
    <>
      <Wrapper bg={colors.footerBlack} pt={100}>
        <Flex flexDirection={['column', 'row-reverse', 'row-reverse']}>
          <Image
            style={{ objectFit: 'contain', maxWidth: '100%' }}
            src={CarolBaskin}
          />
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            mt={[50, 0, 0]}
          >
            <Text variant={'h900Hero'} color={colors.white}>
              Don&#39;t take our word for it
            </Text>
            <Text variant={'h600'} color={colors.white}>
              Grid has unlocked millions of dollars for its members. See what
              they have to say:
            </Text>
          </Box>
        </Flex>
        <Flex mt={100} alignItems="center" justifyContent="center">
          <ReviewBubble
            image={ReviewerOne}
            review="I already got my advance! Fast and easy."
            reviewer="Erika"
          />
          <Box display={['none', 'block', 'block']} ml={30}>
            <ReviewBubble
              image={ReviewerTwo}
              review="What an excellent job they done for me and my family!"
              reviewer="Nick"
            />
          </Box>
        </Flex>
      </Wrapper>
      <div style={{ backgroundColor: colors.black }}>
        <BottomCircularRect fill={colors.footerBlack} />
        <Wrapper mt={100} textAlign="center" pb={100}>
          <Text variant={'h900Hero'} color={colors.white} mb={50}>
            Get Started For Free.
          </Text>
          <AppDownloadButtons
            ctaStyle={SMS_LEAD_GEN_WITH_APP_STORE_DOWNLOAD_BUTTONS}
            context={'FOOTER'}
          />
        </Wrapper>
      </div>
    </>
  );
}
