import { Box, Flex, Heading, Image, Text } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import textSerializer from './textSerializer';
import BlockContent from '@sanity/block-content-to-react';
import { PrimaryButton } from './button';
import { navigate } from '@reach/router';

export function HowItWorks({ title, items }) {
  return (
    <Wrapper className={'headerTransitionTrigger'} bg="bgPale" pb={'120px'}>
      <Flex
        width={['100%', '100%', 643]}
        pt={'80px'}
        ml={'auto'}
        mr={'auto'}
        flexDirection={'column'}
      >
        <Heading
          mb={[55, 55, 62]}
          sx={{
            fontFamily: 'Poppins',
            fontWeight: [500, 500, 500],
            fontSize: [28, 28, 32],
            lineHeight: ['40px', '40px', '44px'],
            letterSpacing: '0.2px',
          }}
        >
          {title}
        </Heading>
        <Flex flexDirection={'column'}>
          {items.map(item => {
            const { title, description, illustration, _key } = item;
            return (
              <Item
                key={_key}
                title={title}
                description={description}
                illustration={illustration}
              />
            );
          })}
        </Flex>
        <StillHaveQuestions />
      </Flex>
    </Wrapper>
  );
}

const Item = ({ title, description, illustration }) => {
  return (
    <Box mb={[10, 10, 20]}>
      <Flex>
        <Box width={1 / 6} mr={['15px', '15px', '0px']}>
          <Image src={illustration.asset.url} width={84} />
        </Box>
        <Box width={5 / 6}>
          <Flex flexDirection={'column'} textAlign={'left'}>
            <Heading variant={'text.h600Medium'} mb={'-20px'}>
              {title}
            </Heading>
            <BlockContent blocks={description} serializers={textSerializer} />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

function StillHaveQuestions() {
  return (
    <Flex
      flexDirection={['column', 'column', 'row']}
      justifyContent={'space-around'}
      bg={'#F2F4F6'}
      px={'25px'}
      py={'35px'}
      sx={{ borderRadius: '8px' }}
    >
      <Box mb={['15px', '15px', '0']} textAlign={'center'}>
        <Text
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '32px',
          }}
        >
          Still have questions?
        </Text>
      </Box>
      <Box textAlign={'center'}>
        <PrimaryButton
          bg={'teal300'}
          onClick={() => {
            navigate('/help/');
          }}
        >
          Learn More
        </PrimaryButton>
      </Box>
    </Flex>
  );
}

export default HowItWorks;
