import { Box, Heading, Image, Text, Flex } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import colors from '../components/themes/colors';

// @ts-ignore
import MoneyEnvelope from '../assets/images/MoneyEnvelope.png';
import LightningBolt from '../assets/svgs/LightningBolt.svg';

export function BoostCardPromo(props) {
  return (
    <div style={{ maxWidth: '100vw', overflow: 'hidden' }}>
      <Box id="promo" className="roundedTop roundedBottom" mt={50}>
        <Wrapper
          css={{
            background:
              'linear-gradient(155.98deg, #4840BB -9.07%, #241D89 89.68%)',
          }}
          pt={50}
          pb={100}
        >
          <Flex
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <LightningBolt style={{ height: 34, width: 34, marginTop: 20 }} />
            <Box pt={50}>
              <Heading
                sx={{
                  fontFamily: 'Poppins',
                  fontStyle: 'bold',
                  fontWeight: 800,
                  fontSize: ['30px', '30px', '30px'],
                  lineHeight: ['40px', '40px', '44px'],
                }}
                textAlign="center"
                color="white"
              >
                Don’t miss out...
              </Heading>

              <Image src={MoneyEnvelope} width={108} mt={20} mb={20} />

              <Heading
                sx={{
                  fontFamily: 'Poppins',
                  fontStyle: 'bold',
                  fontWeight: 800,
                  fontSize: ['30px', '30px', '30px'],
                  lineHeight: ['40px', '40px', '44px'],
                }}
                textAlign="center"
                color="white"
                mb={30}
              >
                Top 10 users get $5,000 each!
              </Heading>
              <Text variant={'h400Regular'} color={colors.white}>
                Top 1,000 get free metal card! Invite friends and move up the
                list!
              </Text>
            </Box>
          </Flex>
        </Wrapper>
      </Box>
    </div>
  );
}
