import * as React from 'react';
import clientrpc, { TwirpError } from '../api/clientrpc';
import { Box, Heading, Text } from 'rebass';
import {
  errorDefaultMessage,
  errorPasswordComplexityNotComplex,
  string,
} from '../copy/Strings.gen';
import Wrapper from './wrapper';
import { visor } from '../protos';
import naclutil from 'tweetnacl-util';
import sealedbox from 'tweetnacl-sealedbox-js';
import { useForm } from 'react-hook-form';
import { FormButton } from './button';
import FormInput from './inputs/formInput';
import { navigate } from 'gatsby';
import { useLocation, WindowLocation } from '@reach/router';

async function doFinishForgotPassword(
  req: visor.clientrpc.IFinishForgotPasswordRequest,
): Promise<{ result: 'SUCCESS' | 'FAILED'; message: string }> {
  try {
    await clientrpc.finishForgotPassword(req);
    return {
      result: 'SUCCESS',
      message:
        'Your password is reset! Please return to Grid app and login with your new password.',
    };
  } catch (ex) {
    if (ex instanceof TwirpError && ex.meta) {
      const error = string({ key: ex.meta.localized_message });
      return {
        result: 'FAILED',
        message: error ?? errorDefaultMessage,
      };
    } else {
      return {
        result: 'FAILED',
        message: errorDefaultMessage,
      };
    }
  }
}

function getURLSearchParam(key: string): string {
  const params = new URLSearchParams(location.search);
  return params.get(key);
}

function FinishForgotPasswordPage() {
  const token = getURLSearchParam('token');
  const code = getURLSearchParam('code');

  const location = useLocation() as WindowLocation<{ success?: boolean }>;

  const forgotPasswordForm = useForm<{ newPassword: string }>({});
  const { handleSubmit, setError, clearError, formState } = forgotPasswordForm;

  // Password Reset success state
  if (location.state?.success) {
    return (
      <Wrapper pt={4} pb={5} minHeight={300}>
        <Heading>Success</Heading>
        <Text variant={'h400Regular'} my={3}>
          Your password has been updated. Please return to Grid app.
        </Text>
      </Wrapper>
    );
  }
  // Invalid link - missing url params
  if (!token || !code) {
    return (
      <Wrapper pt={4} pb={5} minHeight={300}>
        <Heading>Something went wrong</Heading>
        <Text variant={'h200Regular'} my={2}>
          This password reset link isn’t working. Please go back to Grid app and
          try again.
        </Text>
        <Text variant={'h200Regular'} my={2}>
          If you’d like some assistance, please contact our support team at
          support@getgrid.app.
        </Text>
      </Wrapper>
    );
  }

  // Password Reset Form
  return (
    <Wrapper pt={4} pb={5} minHeight={300}>
      <Heading>Password Reset</Heading>
      <Text variant={'h200Regular'} mb={2}>
        {errorPasswordComplexityNotComplex}
      </Text>
      <form
        onSubmit={handleSubmit(async data => {
          const encryptedCode = sealedbox.seal(
            naclutil.decodeUTF8(code ?? ''),
            naclutil.decodeBase64(
              process.env.GATSBY_VISOR_GETGRIDAPP_PASSWORD_PUBLIC_KEY,
            ),
          );
          const encryptedNewPassword = sealedbox.seal(
            naclutil.decodeUTF8(data.newPassword ?? ''),
            naclutil.decodeBase64(
              process.env.GATSBY_VISOR_GETGRIDAPP_PASSWORD_PUBLIC_KEY,
            ),
          );

          const { result, message } = await doFinishForgotPassword({
            token: token,
            encryptedCode: naclutil.encodeBase64(encryptedCode),
            encryptedNewPassword: naclutil.encodeBase64(encryptedNewPassword),
          });

          switch (result) {
            case 'SUCCESS':
              clearError('newPassword');
              navigate('/reset-password', {
                replace: true,
                state: { success: true },
              });
              break;
            case 'FAILED':
              setError('newPassword', { type: 'custom', message: message });
              break;
          }
        })}
      >
        <FormInput
          name="newPassword"
          type="password"
          form={forgotPasswordForm}
          label="New Password"
          placeholder="New Password"
          disabled={formState.isSubmitting}
          validation={{
            validate: {
              containsUpperCase: value =>
                value.match(/[A-Z]/)
                  ? undefined
                  : 'Must contain at least one uppercase letter.',
              containsLowerCase: value =>
                value.match(/[a-z]/)
                  ? undefined
                  : 'Must contain at least one lowercase letter.',
              containsNumber: value =>
                value.match(/\d/)
                  ? undefined
                  : 'Must contain at least one number.',
            },
          }}
        />
        <Box my={2}>
          <FormButton loading={formState.isSubmitting}>Submit</FormButton>
        </Box>
      </form>
    </Wrapper>
  );
}

export default FinishForgotPasswordPage;
