import { Flex, Box, Heading } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import CTA from './cta';

export default function Clincher(props) {
  return (
    <Wrapper bg="teal300">
      <Box bg="teal300" py={130} pr={30}>
        <Flex flexDirection={['column', 'column', 'row']}>
          <Box flex={1}>
            <Heading
              sx={{
                color: 'white',
                fontFamily: 'heading',
                fontWeight: 500,
                fontSize: '50px',
                lineHeight: '75px',
                letterSpacing: '-0.03em',
              }}
            >
              {props.text}
            </Heading>
          </Box>
          <Box flex={1} pt={20} pl={[0, 0, 30]}>
            <Flex flexDirection="column">
              <CTA
                ctaStyle={props.cta}
                context="FOOTER"
                label={props.ctaLabel}
                legal={props.legal}
              />
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Wrapper>
  );
}
