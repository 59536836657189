import React from 'react';
import { Box, Flex, Image, Text } from 'rebass';
// @ts-ignore
import sparkle from '../../assets/images/sparkle.png';
import { isAndroid, isIOS } from 'react-device-detect';
import { APP_DOWNLOAD_ANDROID, APP_STORE_ID } from '../appStoreButtons';
import { NavCTAButton } from '../button';
import SwiperSlideComponent from '../swiperSlideComponent';

const UpdatedPricing = ({ features }: PricingTableProps) => {
  return (
    <SwiperSlideComponent style={{ overflowY: 'auto' }}>
      <Flex
        justifyContent="center"
        bg="#0C003F"
        pt={['40%', '25%', '15%']}
        pb="10%"
        minHeight="100dvh"
        sx={{
          background:
            'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
        }}
      >
        <PricingTable features={features} />
      </Flex>
    </SwiperSlideComponent>
  );
};

type PricingTableProps = {
  features: Array<PricingRowProps>;
};

const PricingTable = ({ features }) => {
  return (
    <Flex flexDirection="column">
      {/* Top row */}
      <Flex mx={[23, 75, 125]} flexDirection="column">
        <Flex mb="5%" justifyContent="space-between" alignItems="center">
          <Flex width={['58%', '58%', '50%']}>
            {/* Features Header */}
            <Text color="white" variant="h920">
              Features
            </Text>
          </Flex>
          <Flex width={['50%', '47.5%', '50%']} justifyContent="space-evenly">
            {/* Free $0 */}
            <Flex flexDirection="column" alignItems="center" width="30%">
              <Text
                color="white"
                fontFamily="heading2"
                fontWeight="extrabold"
                fontSize={[16, 30, 58]}
                lineHeight="140%"
              >
                Free
              </Text>
              <Text
                color="white"
                fontFamily="heading2"
                fontWeight="extrabold"
                fontSize={[10, 22, 40]}
                lineHeight="140%"
              >
                $0
              </Text>
            </Flex>
            {/* Grid+ $10/month */}
            <Flex flexDirection="column" alignItems="center" width="30%">
              <Text
                color="white"
                fontFamily="heading2"
                fontWeight="extrabold"
                fontSize={[16, 30, 58]}
                lineHeight="140%"
              >
                Grid+
              </Text>
              <Text
                color="white"
                fontFamily="heading2"
                fontWeight="extrabold"
                fontSize={[10, 22, 40]}
                lineHeight="140%"
              >
                $10/month
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {/* Separator */}
        <Box height={1.5} bg="neon" />
      </Flex>
      {/* Features Table */}
      <Flex flexDirection="column" mx={[23, 75, 125]} mb="3%">
        {features.map((feature, i) => {
          return <PricingRow key={i} {...feature} />;
        })}
      </Flex>
      <Flex mx={[23, 75, 125]}>
        <NavCTAButton
          variant="neon"
          onClick={() => {
            if (isIOS) {
              open(APP_STORE_ID);
            } else if (isAndroid) {
              open(APP_DOWNLOAD_ANDROID);
            } else {
              open(APP_STORE_ID);
            }
          }}
          sx={{
            border: '1.5px solid #0C003F',
            ':hover': {
              border: '1.5px solid white',
            },
          }}
        >
          Get Started
        </NavCTAButton>
      </Flex>
    </Flex>
  );
};

type PricingRowProps = {
  title: string;
  description: string;
  isFree: boolean;
  isPlus: boolean;
  withSeparator: boolean;
};

const PricingRow = ({
  title,
  description,
  isFree,
  isPlus,
  withSeparator,
}: PricingRowProps) => {
  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between">
        {/* Row */}
        <Flex width={['45%', '40%', '30%']}>
          {/* Description */}
          <Flex flexDirection="column" justifyContent="center" my="7.5%">
            <Text color="white" variant="subH920" fontWeight="extrabold">
              {title}
            </Text>
            <Text color="white" variant="subH920">
              {description}
            </Text>
          </Flex>
        </Flex>
        <Flex width={['50%', '50%', '57.5%']} justifyContent="space-evenly">
          <Flex
            width={['25%', '25%', '21%']}
            alignItems="center"
            justifyContent="flex-end"
          >
            {/* Free Feature */}
            <Image
              display={isFree ? 'block' : 'none'}
              src={sparkle}
              width={['80%', '50%', '37.5%']}
              sx={{
                objectFit: 'contain',
              }}
            />
          </Flex>
          <Flex
            width={['25%', '25%', '21%']}
            alignItems="center"
            justifyContent="flex-end"
          >
            {/* Grid+ Feature */}
            <Image
              display={isPlus ? 'block' : 'none'}
              src={sparkle}
              width={['80%', '50%', '37.5%']}
              sx={{
                objectFit: 'contain',
              }}
            />
          </Flex>
        </Flex>
      </Flex>
      {/* Separator */}
      <Box
        display={withSeparator === true ? 'block' : 'none'}
        height={1.5}
        bg="neon"
      />
    </Flex>
  );
};

export default UpdatedPricing;
