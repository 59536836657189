import React, { useState } from 'react';
import { Flex, Heading, Text, Box } from 'rebass';
import { OverlayRoute } from '../overlay';
import FormInput from '../inputs/formInput';
import { useForm } from 'react-hook-form';
import { emailValidator } from '../validations';
import { FormButton } from '../button';
import WaitingIllo from '../../assets/svgs/Waiting.svg';
import { useTheme } from 'emotion-theming';
import clientrpc from '../../api/clientrpc';
import { visor } from '../../protos';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import { serverErrorToast } from '../toaster';

const AndroidOverlay = () => {
  const theme = useTheme();
  const form = useForm();
  const { handleSubmit } = form;
  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async data => {
    const email = data.email;

    const req = new visor.clientrpc.CreateAndroidLeadRequest();
    req.email = email;
    setSubmitting(true);
    try {
      await clientrpc.createAndroidLead(req);
      toast.success('Thanks for joining the waitlist! Stay tuned for updates.');
    } catch (ex) {
      serverErrorToast();
      return;
    } finally {
      setSubmitting(false);
    }
    navigate('/');
  };

  return (
    <OverlayRoute path={'APP_DOWNLOAD_ANDROID'}>
      <Box width={'100%'} sx={{ position: 'relative' }}>
        <Flex
          width={['100%', '100%', '800px']}
          sx={{
            position: ['relative', 'relative', 'absolute'],
            top: [null, null, '50%'],
            left: [null, null, '50%'],
            transform: [null, null, 'translate(-50%, 30%)'],
          }}
        >
          <Flex width={[1, 1, 0.64]} flexDirection={'column'}>
            <Heading
              sx={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: ['28px', '28px', '50px'],
                lineHeight: ['40px', '40px', '75px'],
                letterSpacing: ['0.2px', '0.2px', '-0.03em'],
              }}
            >
              We’re working hard on our Android app
            </Heading>
            <Text
              mt={11}
              sx={{
                fontFamily: 'Poppins',
                fontSize: [
                  theme.text.h400Regular.fontSize,
                  theme.text.h400Regular.fontSize,
                  theme.text.h500Regular.fontSize,
                ],
                lineHeight: [
                  theme.text.h400Regular.lineHeight,
                  theme.text.h400Regular.lineHeight,
                  theme.text.h500Regular.lineHeight,
                ],
              }}
            >
              If you give us your email, we’ll let you know when Android is
              ready for you.
            </Text>
            <Box
              as={'form'}
              onSubmit={handleSubmit(onSubmit)}
              maxWidth={'384px'}
            >
              <FormInput
                type={'email'}
                form={form}
                validation={emailValidator}
                name={'email'}
                placeholder={'Email'}
                label={'Email'}
                disabled={submitting}
              />
              <FormButton loading={submitting} type={'submit'}>
                Submit
              </FormButton>
            </Box>
          </Flex>
          <Box
            width={0.36}
            flex={1}
            display={['none', 'none', 'block']}
            sx={{ textAlign: 'center' }}
          >
            <WaitingIllo />
          </Box>
        </Flex>
      </Box>
    </OverlayRoute>
  );
};

export default AndroidOverlay;
