import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

import Wrapper from './wrapper';
import textSerializer from './textSerializer';

interface ITextWallProps {
  lastUpdated: Date;
  textBlocks: any;
}

function TextWall(props: ITextWallProps) {
  return (
    <Wrapper mb={[5, 5, 6]}>
      <BlockContent blocks={props.textBlocks} serializers={textSerializer} />
    </Wrapper>
  );
}

export default TextWall;
