import { Box, Flex, Image, Heading, Text } from 'rebass';
import React from 'react';

import CTA from './cta';
import Wrapper from './wrapper';
import BottomWave from '../assets/svgs/BottomWave.svg';
import BottomWaveSM from '../assets/svgs/BottomWaveSM.svg';
import colors from './themes/colors';

export function Hero({
  text,
  subtext,
  ctaStyle,
  imageStyle,
  illustration,
  illustrationMobile,
}) {
  const illoUrl = illustration ? illustration.asset.url : null;
  const illoMobileUrl = illustrationMobile
    ? illustrationMobile.asset.url
    : null;

  return (
    <Box alignItems="center">
      <Wrapper width="100%" bg="teal300" marginBottom="-1px">
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          pt={[0, 0, 50]}
          pb={[250, 200, 0]}
          width="100%"
        >
          <Heading
            sx={{
              fontFamily: 'Poppins',
              fontStyle: 'medium',
              fontWeight: 800,
              fontSize: ['46px', '62px', '62px'],
              lineHeight: ['58px', '58px', '58px'],
              color: colors.white,
              marginBottom: 15,
            }}
            textAlign="center"
          >
            {text}
          </Heading>
          <Text
            variant="h600"
            color="white"
            textAlign="center"
            pt={25}
            width={['100%', '100%', '80%']}
          >
            {subtext}
          </Text>
          <Box mt={[40, 40, 40]}>
            <CTA ctaStyle={ctaStyle} context="HERO" />
          </Box>
        </Flex>
      </Wrapper>
      <Box display={['none', 'none', 'block']}>
        <BottomWave width="100%" fill={colors.teal300} />
      </Box>
      <Box display={['block', 'block', 'none']}>
        <BottomWaveSM width="100%" fill={colors.teal300} />
      </Box>
      <Wrapper mt={['-300px', '-250px', '-20%']}>
        <Flex justifyContent="center" alignItems="center">
          <Image
            display={['none', 'block', 'block']}
            src={illoUrl}
            sx={{ imageStyle }}
            width="595px"
            height="664px"
          />
          <Image
            display={['block', 'none', 'none']}
            src={illoMobileUrl}
            sx={{ imageStyle }}
            width="315px"
            height="476px"
          />
        </Flex>
      </Wrapper>
    </Box>
  );
}
