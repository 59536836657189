import React from 'react';
import { Flex, Text, Image } from 'rebass';

const LandingSecurityPanel = ({
  header,
  description,
  bgColor,
  txtColor,
  imageUrl,
  imageMediumUrl,
  imageMobileUrl,
}) => {
  return (
    <Flex
      height="100dvh"
      width="100dvw"
      bg={bgColor}
      alignItems={['flex-end', 'flex-end', 'flex-start']}
      sx={{
        background:
          'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
    >
      <Flex
        justifyContent={['center', 'center', 'space-between']}
        alignItems={['center', 'center', 'center']}
        flexDirection={['column', 'column', 'row-reverse']}
        width="100%"
        height={['calc(100% - 105px)', 'calc(100% - 105px)', '100%']}
        ml={[23, 75, 125]}
        mr={[23, 75, 0]}
      >
        <Flex
          width={['auto', 'auto', '65%']}
          maxHeight={['none', '50%', '100%']}
          mb={[20, 20, 0]}
          mx="auto"
          justifyContent={['center', 'center', 'center']}
          overflow="hidden"
        >
          <Image
            display={['none', 'none', 'flex']}
            src={imageUrl}
            sx={{
              transform: 'scale(1.05)',
              objectFit: 'contain',
            }}
          />
          <Image
            display={['flex', 'none', 'none']}
            src={imageMobileUrl}
            sx={{
              objectFit: 'contain',
            }}
          />
          <Image
            display={['none', 'flex', 'none']}
            src={imageMediumUrl || imageMobileUrl}
            sx={{
              objectFit: 'contain',
            }}
          />
        </Flex>
        <Flex
          flexDirection={'column'}
          justifyContent={'center'}
          width={['auto', 'auto', '37.5%']}
          height={['auto', 'auto', 'calc(100% - 105px)']}
          sx={{
            zIndex: 30,
          }}
        >
          <Text variant={'h920'} color={txtColor}>
            {header}
          </Text>
          <Text
            variant={'subH920'}
            mt={[20, 30, 30]}
            color={txtColor}
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            {description}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LandingSecurityPanel;
