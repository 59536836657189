import { useState, useEffect, useCallback } from 'react';

const useReducedMotion = (defaultValue = false) => {
  const [reducedMotion, setReducedMotion] = useState(defaultValue);

  const queryChangeHandler = useCallback(event => {
    setReducedMotion(event.target.matches);
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    if (mediaQuery) {
      setReducedMotion(mediaQuery.matches);

      mediaQuery.addEventListener('change', queryChangeHandler);

      return () => mediaQuery.removeEventListener('change', queryChangeHandler);
    }
  }, [queryChangeHandler]);

  return reducedMotion;
};

export default useReducedMotion;
