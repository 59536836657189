import { Image, Text, Flex } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';

import Coins from '../assets/images/Coins.png';
import GreenCheck from '../assets/images/GreenCheck.png';
import BoostSparkles from '../assets/images/BoostSparkles.png';

export function PayboostClincher(props) {
  return (
    <Wrapper>
      <Flex
        mt={80}
        mb={80}
        flexDirection={['column', 'row', 'row']}
        alignItems="center"
        textAlign="center"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Image src={Coins} />
          <Text mt={20} variant={'h600Bold'}>
            Start Streaking
          </Text>
          <Text variant={'h400Regular'}>
            The more monthly check-ins you complete, the more PayBoost you get.
          </Text>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={[30, 0, 0]}
        >
          <Image mt={[0, 10, 10]} src={GreenCheck} />
          <Text mt={[20, 45, 45]} variant={'h600Bold'}>
            Accuracy Guarantee
          </Text>
          <Text variant={'h400Regular'}>
            If we make a mistake, we cover it. Nothing but upsides for you.
          </Text>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={[30, 0, 0]}
        >
          <Image src={BoostSparkles} />
          <Text mt={20} variant={'h600Bold'}>
            100% Flexible
          </Text>
          <Text variant={'h400Regular'}>
            Each month you can choose how much PayBoost you want.
          </Text>
        </Flex>
      </Flex>
    </Wrapper>
  );
}
