import { Box, Flex, Text, Image, Button } from 'rebass';
import React from 'react';
import { navigate } from '@reach/router';

const LandingCardPanel = ({
  bgColor,
  imageUrl,
  imageMediumUrl,
  imageMobileUrl,
  header,
  txtColor,
  description,
  buttonText,
}) => {
  return (
    <Flex
      height="100dvh"
      width="100dvw"
      bg={bgColor}
      alignItems="flex-end"
      overflow={['visible', 'visible', 'hidden']}
      sx={{
        background:
          'radial-gradient(100% 316.05% at 100% 100%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
      }}
    >
      <Flex
        justifyContent={['center', 'center', 'flex-end']}
        alignItems="center"
        flexDirection={['column', 'column', 'row-reverse']}
        width={'100%'}
        height="calc(100% - 105px)"
      >
        <Box
          display={['none', 'none', 'flex']}
          width={'100%'}
          height={'100%'}
          sx={{
            position: ['', '', 'absolute'],
            backgroundImage: ['', '', `url(${imageUrl})`],
            backgroundSize: ['', '', 'cover'],
            backgroundPosition: ['', '', 'initial'],
            backgroundRepeat: 'no-repeat',
          }}
        />
        <Flex
          height={['50%', 'auto', 'auto']}
          maxHeight={['none', 'none', '100%']}
          width={['', '100%', '55%']}
          mb={['7.5%']}
          sx={{
            zIndex: 30,
          }}
        >
          <Image
            display={['none', 'none', 'flex']}
            src={imageMobileUrl}
            sx={{
              objectFit: 'contain',
              transform: 'scale(1.2)',
              objectPosition: '75% top',
            }}
          />
          <Image
            display={['flex', 'none', 'none']}
            src={imageMediumUrl}
            sx={{
              objectFit: 'cover',
              objectPosition: '75% 75%',
              transform: 'scale(1.1)',
            }}
          />
          <Image
            display={['none', 'block', 'none']}
            src={imageMediumUrl}
            sx={{
              objectFit: 'contain',
            }}
          />
        </Flex>
        <Flex
          flexDirection={'column'}
          justifyContent={'center'}
          ml={[23, 75, 125]}
          mr={[23, 75, 0]}
          width={['', '', '37.5%']}
          sx={{
            zIndex: 30,
          }}
        >
          <Text variant="h920" color={txtColor}>
            {header}
          </Text>

          <Text variant="subH920" mt={[20, 30, 30]} color={txtColor}>
            {description}
          </Text>
          <Flex mt={[15, 30, 45]}>
            <Button
              variant="neon"
              onClick={() => navigate('/card')}
              sx={{
                border: `1.5px solid ${bgColor}`,
                ':hover': {
                  border: '1.5px solid white',
                },
              }}
            >
              {buttonText}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LandingCardPanel;
