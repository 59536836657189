import React from 'react';
import { Flex, Box } from 'rebass';
import { OverlayRoute } from '../overlay';
import EmailForm from '../eligibility/email';
import { ReviewBubble } from '../reviewClincher';
// @ts-ignore
import ReviewerOne from '../../assets/images/ReviewerOne.png';

const EligibilityOverlay = () => {
  return (
    <OverlayRoute path={'eligibility/email'}>
      <Flex
        flexDirection={['column', 'row', 'row']}
        justifyContent="space-between"
        alignItems="center"
      >
        <EmailForm />
        <Box mt={[150, 0, 0]}>
          <ReviewBubble
            image={ReviewerOne}
            review="I already got my advance! Fast and easy."
            reviewer="Erika"
          />
        </Box>
      </Flex>
    </OverlayRoute>
  );
};

export default EligibilityOverlay;
