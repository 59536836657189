import { Box, Flex, Heading, Text } from 'rebass';
import React from 'react';
import Wrapper from './wrapper';
import { InternalLink, ExternalLink } from './link';
import {
  GooglePlayIconRoundButton,
  AppleAppStoreIconRoundButton,
} from './appStoreButtons';
import { useLocation } from '@reach/router';
import Divider from './divider';

const FOOTER_COLUMN_WIDTH = ['184px', '184px', '226px'];

export function Footer(props) {
  if (!props) {
    return null;
  }
  const location = useLocation();
  return (
    <swiper-slide>
      <Wrapper
        isLegacy={props.isLegacy}
        bg={props.isLegacy ? '#26272E' : 'neon'}
        pt={76}
        pb={50}
      >
        <Flex
          mx={!props.isLegacy && [23, 75, 125]}
          bg={props.isLegacy ? '#26272E' : 'neon'}
          flexDirection="column"
        >
          <Flex>
            <Flex flexDirection="column" alignItems="stretch">
              <Flex
                flexDirection="row"
                justifyItems="spaceBetween"
                alignItems="stretch"
              >
                <LinksColumn
                  isLegacy={props.isLegacy}
                  links={props.leftLinks}
                  title={props.leftLinksTitle}
                />
                <LinksColumn
                  isLegacy={props.isLegacy}
                  links={props.midLinks}
                  title={props.midLinksTitle}
                />
                <LinksColumn
                  isLegacy={props.isLegacy}
                  links={props.rightLinks}
                  title={props.rightLinksTitle}
                />
              </Flex>
              <Flex
                mt={['24px', '24px', '55px']}
                flexDirection={[
                  props.rightLinks?.length > 0 ? 'row' : 'column',
                  props.rightLinks?.length > 0 ? 'row' : 'column',
                  'row',
                ]}
              >
                <InternalLink
                  to={`/terms`}
                  sx={{
                    width: FOOTER_COLUMN_WIDTH,
                    color: props.isLegacy ? 'white' : 'black',
                    opacity: '60%',
                    mt: [
                      props.rightLinks?.length > 0 ? 0 : 1,
                      props.rightLinks?.length > 0 ? 0 : 1,
                      0,
                    ],
                    cursor: 'pointer',
                    fontFamily: props.isLegacy
                      ? 'Poppins'
                      : 'nimbus-sans-extended',
                    fontWeight: 500,
                    fontSize: ['11px', '11px', '14px'],
                    lineHeight: '20px',
                    letterSpacing: '0.2px',
                    ':hover': {
                      color: props.isLegacy ? 'white' : 'black',
                    },
                  }}
                >
                  Terms and Conditions
                </InternalLink>
                <InternalLink
                  to={`/privacy`}
                  sx={{
                    width: FOOTER_COLUMN_WIDTH,
                    color: props.isLegacy ? 'white' : 'black',
                    opacity: '60%',
                    mt: [
                      props.rightLinks?.length > 0 ? 0 : 3,
                      props.rightLinks?.length > 0 ? 0 : 3,
                      0,
                    ],
                    cursor: 'pointer',
                    fontFamily: props.isLegacy
                      ? 'Poppins'
                      : 'nimbus-sans-extended',
                    fontWeight: 500,
                    fontSize: ['11px', '11px', '14px'],
                    lineHeight: '20px',
                    letterSpacing: '0.2px',
                    ':hover': {
                      color: props.isLegacy ? 'white' : 'black',
                    },
                  }}
                >
                  Privacy Policy
                </InternalLink>
              </Flex>
            </Flex>
            <FooterActions
              isLegacy={props.isLegacy}
              location={location}
              footerActionTitle={props.footerActions.footerActionTitle}
              footerAction={props.footerActions.footerAction}
            />
          </Flex>
          <Flex flexDirection="column">
            <Flex my={[30, 40, 50]}>
              <Divider bg="black" opacity={0.6} />
            </Flex>
            <Flex flexDirection="column">
              <Text
                variant={props.isLegacy ? 'h300Medium' : 'h300MediumUpdated'}
                color={props.isLegacy ? 'white' : 'black'}
                opacity={0.6}
              >
                Interest checking accounts are established by Evolve Bank &
                Trust, Member FDIC. Funds are FDIC insured, subject to
                applicable limitations and restrictions when we receive the
                funds deposited to your account. Grid account terms, conditions
                and fees apply. Please see the Interest Checking Account
                Agreement for complete details. Cards are issued by Evolve Bank
                & Trust, Member FDIC pursuant to a license by Mastercard®. All
                loans are made by{' '}
                <ExternalLink to="https://synapsefi.com/lending">
                  Synapse Credit LLC
                </ExternalLink>
                , NMLS 1971454, a licensed U.S. lender in{' '}
                <ExternalLink to="https://synapsefi.com/lending">
                  designated States
                </ExternalLink>
                . Please see your loan documents for details.
                <br />
                <br />
                1) Individual results may vary. Some users may not receive an
                improved score.
                <br />
                2) Grid is not a bank, but works with FDIC insured depository
                institutions in the United States to offer deposit products.
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Wrapper>
    </swiper-slide>
  );
}

const FooterActions = ({ footerActionTitle, footerAction, isLegacy }) => {
  switch (footerAction) {
    case 'ROUND_DOWNLOAD_ICONS':
      return (
        <Box ml="auto" display={['none', 'none', 'block']}>
          <Heading
            variant={isLegacy ? 'h300Medium' : 'h300MediumUpdated'}
            color={isLegacy ? 'white' : 'black'}
            opacity={0.6}
            mb={28}
            textAlign="center"
          >
            {footerActionTitle}
          </Heading>
          <Flex flexDirection="row">
            <Box mr="5px">
              <AppleAppStoreIconRoundButton
                context={'FOOTER'}
                isLegacy={isLegacy}
              />
            </Box>
            <Box ml="5px">
              <GooglePlayIconRoundButton
                context={'FOOTER'}
                isLegacy={isLegacy}
              />
            </Box>
          </Flex>
        </Box>
      );
    default:
      console.warn('No footer action defined');
      return null;
  }
};

const footerLinkStyles = {
  fontFamily: 'body',
  textDecoration: 'inherit',
  cursor: 'pointer',
  color: 'white',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '40px',
  whiteSpace: 'nowrap',
  ':hover': {
    color: 'white',
    opacity: '60%',
  },
  ':active': {
    color: 'white',
  },
};

const updatedFooterLinkStyles = {
  fontFamily: 'nimbus-sans-extended',
  textDecoration: 'inherit',
  cursor: 'pointer',
  color: 'black',
  fontWeight: 500,
  fontSize: ['13px', '13px', '18px'],
  lineHeight: '40px',
  whiteSpace: 'nowrap',
  ':hover': {
    color: 'black',
    opacity: '60%',
  },
  ':active': {
    color: 'black',
  },
};

const LinksColumn = ({ links, title, isLegacy }) => (
  <Box width={FOOTER_COLUMN_WIDTH}>
    <Heading
      variant={isLegacy ? 'h300Medium' : 'h300MediumUpdated'}
      color={isLegacy ? 'white' : 'black'}
      opacity={0.6}
    >
      {title}
    </Heading>
    <Flex flexDirection="column" mt={3}>
      {links.map(link => {
        switch (link.__typename) {
          case 'SanityExternalLink':
            return (
              <ExternalLink
                key={link._key}
                to={link.url}
                sx={isLegacy ? footerLinkStyles : updatedFooterLinkStyles}
              >
                {link.text}
              </ExternalLink>
            );
          case 'SanityInternalLink':
            return (
              <InternalLink
                key={link._key}
                to={link.page.slug.current}
                sx={isLegacy ? footerLinkStyles : updatedFooterLinkStyles}
              >
                {link.text}
              </InternalLink>
            );
          default:
            return null;
        }
      })}
    </Flex>
  </Box>
);
