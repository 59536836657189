import React, { useState, useEffect } from 'react';
import { Box } from 'rebass';
import { ClassNames } from '@emotion/core';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import GridLogo from '../assets/svgs/GridLogo.svg';
import { PrimaryButton } from './button';

const commonStyles = {
  transitionProperty: 'transform, opacity',
  transitionDuration: '800ms',
  position: 'absolute',
  top: 0,
} as const;

const hiddenInStyles = {
  opacity: 0,
  transform: 'rotateX(90deg) translateY(-1em)',
  ...commonStyles,
} as const;

const visibleStyles = {
  opacity: 1,
  transform: 'rotateX(0deg) translateY(0)',
  ...commonStyles,
};

const hiddenExitStyles = {
  opacity: 0,
  transform: 'rotateX(-90deg) translateY(1em)',
  ...commonStyles,
};

const TextTransition = props => {
  const { strs, ...rest } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const str = strs[currentIndex];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(i => (i + 1) % strs.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box {...rest}>
      <ClassNames>
        {({ css }) => (
          <TransitionGroup>
            <CSSTransition
              key={str}
              timeout={500}
              classNames={{
                appear: css(hiddenInStyles),
                appearActive: css(visibleStyles),
                enter: css(hiddenInStyles),
                enterActive: css(visibleStyles),
                enterDone: css(visibleStyles),
                exit: css(visibleStyles),
                exitActive: css(hiddenExitStyles),
              }}
              appear
            >
              <Box>{str}</Box>
            </CSSTransition>
          </TransitionGroup>
        )}
      </ClassNames>
    </Box>
  );
};

export function BoostCardHeroV2(props) {
  const headerWithoutLastWord = props.header
    .split(' ')
    .slice(0, -1)
    .join(' ');

  return (
    <div style={{ maxWidth: '100vw', overflow: 'hidden' }}>
      <Box
        css={{
          background:
            'linear-gradient(120.32deg, #06070B 1.59%, #14161F 68.45%)',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${props.illustration.asset.url})`,
            backgroundPosition: 'right top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 50%',
            mixBlendMode: 'luminosity',
            opacity: 0.4,
            '@media screen and (min-width: 600px)': {
              backgroundSize: 'auto 80%',
            },
          }}
        />

        <Box
          opacity={0.2}
          css={{
            position: 'absolute',
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
          }}
          marginTop={[20, 40]}
        >
          <GridLogo />
        </Box>

        <Box
          display="flex"
          color="white"
          fontFamily="Poppins"
          flexDirection={['column', 'row']}
        >
          <Box
            maxWidth={[285, 512]}
            alignSelf={['normal', 'center']}
            ml={[20, 130]}
            mb={[95, 230]}
            mt={[305, 215]}
            order={[2, 1]}
            position="absolute"
            top={0}
          >
            <Box
              fontWeight={600}
              fontSize={['42px', '70px']}
              lineHeight={['58px', '76px']}
              mb={24}
              letterSpacing={['normal', '0.2px']}
            >
              <Box display="inline-block">{headerWithoutLastWord}</Box>{' '}
              <Box display="inline-block" sx={{ position: 'relative' }}>
                every
                <TextTransition
                  strs={props.suffixes.map(s => `${s}.`)}
                  color="#05C890"
                  ml={0.2}
                  display="inline-block"
                />
              </Box>
            </Box>

            <Box
              fontSize={['18px', '20px']}
              lineHeight="32px"
              maxWidth={340}
              mb={[24, 30]}
              letterSpacing="0.2px"
            >
              {props.subtext}
            </Box>

            <PrimaryButton
              className="vrlps-trigger"
              sx={{
                borderRadius: '4px',
                textTransform: 'uppercase',
                letterSpacing: '1.2px',
                fontSize: '14px',
                position: 'relative',
                zIndex: 10,
              }}
              px={20}
            >
              {props.ctaText}
            </PrimaryButton>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
