import React from 'react';

export const BulletCheck = props => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="26.5"
        height="26.5"
        rx="13.25"
        fill="white"
        stroke={props.stroke}
        strokeWidth="1.5"
      />
      <rect width="28" height="28" rx="14" fill={props.fill} />
      <path
        d="M7.95711 14.0429C7.56658 13.6524 6.93342 13.6524 6.54289 14.0429C6.15237 14.4334 6.15237 15.0666 6.54289 15.4571L7.95711 14.0429ZM11 18.5L10.2929 19.2071L11 19.9142L11.7071 19.2071L11 18.5ZM21.4571 9.45711C21.8476 9.06658 21.8476 8.43342 21.4571 8.04289C21.0666 7.65237 20.4334 7.65237 20.0429 8.04289L21.4571 9.45711ZM6.54289 15.4571L10.2929 19.2071L11.7071 17.7929L7.95711 14.0429L6.54289 15.4571ZM11.7071 19.2071L21.4571 9.45711L20.0429 8.04289L10.2929 17.7929L11.7071 19.2071Z"
        fill="white"
      />
    </svg>
  );
};
