import React from 'react';
import { OverlayRoute } from '../overlay';
import Payboost from '../eligibility/payboost';

const EligibilityPayboost = () => {
  return (
    <OverlayRoute path={'eligibility/payboost'}>
      <Payboost />
    </OverlayRoute>
  );
};

export default EligibilityPayboost;
